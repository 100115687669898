import { FunctionComponent } from "react";
import { useHistory, useLocation } from "react-router";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGLayout } from "sg-layout";
import { SGMediaQuery } from "sg-media-query";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { BanniereModeSupervision } from "website/components/molecules/BanniereModeSupervision/BanniereModeSupervision";
import {
  ARTICLES,
  DASHBOARD,
  REFORME_DEPART_ANTICIPE,
  TUNNEL,
} from "website/utils/privateRoutes";
import "./Header.scss";
import { HeaderDesktop } from "./templates/HeaderAssu/HeaderDesktop";
import { HeaderPhone } from "./templates/HeaderAssu/HeaderPhone";
import { isWebView } from "store/import/utils";
import { FormattedMessage } from "react-intl";

const Header: FunctionComponent = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { trackClick } = useTracking();
  const lstNoShowButton = [DASHBOARD, TUNNEL, REFORME_DEPART_ANTICIPE];

  const isArticle = pathname.includes(ARTICLES) && pathname !== ARTICLES;

  return (
    <div className="header">
      <BanniereModeSupervision />
      <SGMediaQuery minwidth="lg">
        <HeaderDesktop />
      </SGMediaQuery>
      <SGMediaQuery minwidth={0} maxwidth="md">
        {!isWebView() && <HeaderPhone />}
      </SGMediaQuery>
      {!lstNoShowButton.includes(pathname) && (
        <SGLayout
          backbutton={
            <SGButton
              onClick={() => {
                trackClick(
                  `clic-sur-${
                    isArticle ? "retour-articles" : "navigation-retour"
                  }`
                );
                if (isArticle) {
                  history.push(ARTICLES);
                } else {
                  history.push(DASHBOARD);
                }
              }}
              icon={
                <SGIcon
                  component={<SGAvenirNavFlecheBtnLien />}
                  currentcolor
                  rotate="left"
                />
              }
              type="tertiary"
            >
              <FormattedMessage
                id={`header.retour${isArticle ? ".articles" : ""}`}
              />
            </SGButton>
          }
        />
      )}
    </div>
  );
};

export { Header };
