import { ElxColor } from "../../website/utils/graphs/colors";
import { Member } from "../members/types";
import { ProjectType } from "../projects/types";

export interface ExpenseState {
   expenses: Expense[];
   hasFetchedExpenses: boolean;
   spendingProfile: SpendingProfile;
   hasFetchedSpendingProfile: boolean;
}

export interface Expense {
   id: number;
   name: string;
   monthlyAmount: number;
   type?: ProjectType;
   member: Member | undefined;
}

export enum SpendingProfile {
   MEDIUM_SAVING = "MEDIUM_SAVING",
   HIGH_SAVING = "HIGH_SAVING",
   NO_SAVING = "NO_SAVING",
}

export type ExpenseCategoryKey =
   | "EXPENSES"
   | "TAX_EXPENSE"
   | "LOAN"
   | "RENT"
   | "CHILD_SUPPORT_EXPENSE"
   | "EXPENSE_PLANNED_SAVINGS"
   | "ASSET_EXPENSE"
   | "AVAILABLE_INCOME"
   | "EMPLOYEE_AT_HOME"
   | "DONATIONS"
   | "CHILDCARE";

export interface ExpenseGraphCategory {
   intlKey: ExpenseCategoryKey;
   color: ElxColor;
}

// actions
export const FETCH_EXPENSES = "FETCH_EXPENSES";
export const EXPENSES_FETCHED = "EXPENSES_FETCHED";
export const SPENDING_PROFILE_UPDATED = "SPENDING_PROFILE_UPDATED";
export const FETCH_SPENDING_PROFILE = "FETCH_SPENDING_PROFILE";
export const SPENDING_PROFILE_FETCHED = "SPENDING_PROFILE_FETCHED";
export const EXPENSE_DELETED = "EXPENSE_DELETED";
