import { FunctionComponent } from "react";
import {
  SGAvenirStyledBourse,
  SGAvenirStyledRetraite,
  SGAvenirStyledSuiviVirement,
} from "sg-icon-pack-base";
import {
  NomPageEnum,
  TabNosSolutionsEnum,
} from "website/components/molecules/NosSolutions/utils";
import { NosSolutionsTemplate } from "../components/templates/NosSolutions/NosSolutionsTemplate";

const NosSolutionsPerPage: FunctionComponent = () => (
  <NosSolutionsTemplate
    nom={NomPageEnum.PER}
    icones={[
      { icone: <SGAvenirStyledSuiviVirement />, key: "suiviVirement" },
      { icone: <SGAvenirStyledRetraite />, key: "retraite" },
      { icone: <SGAvenirStyledBourse />, key: "bourse" },
    ]}
    tabs={[
      TabNosSolutionsEnum.SOUSCRIPTION,
      TabNosSolutionsEnum.ALIMENTATION,
      TabNosSolutionsEnum.FISCALITE,
      TabNosSolutionsEnum.MODE_DE_GESTION,
      TabNosSolutionsEnum.RECUPERATION,
    ]}
    spanSectionPointsCles={3}
    trackingName="plan-epargne-retraite"
  />
);

export { NosSolutionsPerPage };
