import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiDelete, apiPut } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import {
   partnerTaxSystemUpdated,
   taxCreditDeleted,
} from "./taxSlice";
import {
   DELETE_TAX_CREDIT,
   TaxCredit,
   TaxSystem,
   UPDATE_PARTNER_TAX_SYSTEM,
} from "./types";

export function* watcherSaga() {
  yield takeLeading(UPDATE_PARTNER_TAX_SYSTEM, safe(onDefaultError, handleUpdatePartnerTaxSystem));
  yield takeLeading(DELETE_TAX_CREDIT, safe(onDefaultError, handleDeleteTaxCredit));
}

function* handleUpdatePartnerTaxSystem(action: PayloadAction<TaxSystem>) {
   const payload: AxiosResponse<TaxSystem> = yield call(apiPut, `wealth/api/simulation/tax-system/partner?assetTaxSystem=${action.payload}`);
   yield put(partnerTaxSystemUpdated(payload.data));
}

function* handleDeleteTaxCredit(action: PayloadAction<TaxCredit>) {
   yield call(apiDelete, `wealth/api/tax-credit/${action.payload.id}`);
   yield put(taxCreditDeleted(action.payload));
}
