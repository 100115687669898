import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import {
  getBoxMargin,
  getChecklistItem,
  getTextWeight,
} from "website/utils/formatting/ValuesFormattedMessage";

interface ContenusBloc5050Props {
  idTitre: string;
  niveauTitre?: number;
  idTexte: string;
  bouton?: JSX.Element;
}

export const ContenusBloc5050: FC<ContenusBloc5050Props> = ({
  idTitre,
  niveauTitre = 1,
  idTexte,
  bouton,
}) => (
  <SGSpace direction="vertical" disableautomargin size="sm">
    <SGTitle key="titre" level={niveauTitre} visuallevel={2}>
      <FormattedMessage id={idTitre} />
    </SGTitle>
    <SGText key="text" size="l">
      <FormattedMessage
        id={idTexte}
        values={{
          box: (word: string) => getBoxMargin(word, { top: "sm" }),
          b: (word: string) => getTextWeight(word, "600"),
          li: getChecklistItem,
        }}
      />
    </SGText>
    {bouton && <React.Fragment key="bouton">{bouton}</React.Fragment>}
  </SGSpace>
);
