import { AxiosResponse } from "axios";
import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { apiGet } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { expensesFetched, setFetchedExpensesToFalse, spendingProfileFetched } from "./expensesSlice";
import {
   Expense,
   FETCH_EXPENSES,
   FETCH_SPENDING_PROFILE,
   SpendingProfile
} from "./types";

export function* watcherSaga() {
   yield takeEvery(FETCH_SPENDING_PROFILE, safe(onDefaultError, handleGetSpendingProfile));
   yield takeLeading(FETCH_EXPENSES, safe(onDefaultError, handleGetExpenses));
}

function* handleGetSpendingProfile() {
   const payload: AxiosResponse<{ spendingProfile: SpendingProfile }> = yield call(apiGet, `wealth/api/expenses/spending-profile/`);
   yield put(spendingProfileFetched(payload.data.spendingProfile));
}

function* handleGetExpenses() {
   yield put(setFetchedExpensesToFalse());
   const payload: AxiosResponse<Expense[]> = yield call(apiGet, `wealth/api/expenses?ongoingOnly=true`);
   yield put(expensesFetched(payload.data));
}
