import {
   CREATE_RETIREMENT_PROJECT,
   FETCH_PROJECTS,
   RetirementProjectValues,
   UPDATE_RETIREMENT_PROJECT_IN_STORE
} from "./types";

export const getProjectsAction = () => ({
   type: FETCH_PROJECTS
});

export const updateRetirementProjectValuesInStoreAction = (retirementProject: RetirementProjectValues) => ({
   type: UPDATE_RETIREMENT_PROJECT_IN_STORE,
   payload: retirementProject
});

export const createRetirementProjectValuesAction = () => ({
   type: CREATE_RETIREMENT_PROJECT
});
