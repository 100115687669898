import { Fragment, FunctionComponent, ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGSkeleton } from "sg-skeleton";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { DashboardAge, DashboardAgeType } from "store/dashboard/types";
import { getAge, getDateAge } from "store/dashboard/utils";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { InfoTooltip } from "website/components/molecules/InfoTooltip/InfoTooltip";

interface CarteAgeContenuProps {
  age: DashboardAge;
}

export const CarteAgeContenu: FunctionComponent<CarteAgeContenuProps> = (
  props: React.PropsWithChildren<CarteAgeContenuProps>
) => {
  const { age } = props;
  const intl = useIntl();

  const { montantTotal } = useMonPlanMontants(age);

  function getTooltipTypeDepart(typeDepart: DashboardAgeType): ReactNode {
    const texte = intl.formatMessage({
      id: `agesCles.${typeDepart}.tooltip`,
      defaultMessage: "NA",
    });

    return (
      texte !== "NA" && (
        <InfoTooltip text={`agesCles.${typeDepart}.tooltip`} isModal={false} />
      )
    );
  }

  return (
    <SGSpace disableautomargin direction="vertical" size="xs">
      <SGBox key="box-age">
        <>
          <SGText titlerender size="l">
            {getAge(age)}
          </SGText>
          <SGText size="xs" color="lighter">
            {getDateAge(age)}
          </SGText>
        </>
      </SGBox>
      <SGText key="titre">
        {age.typesDepart.map((typeDepart: DashboardAgeType, index: number) => (
          <Fragment key={typeDepart}>
            {index > 0 && " = "}
            <SGText>
              <FormattedMessage id={`agesCles.${typeDepart}`} />
            </SGText>
            {getTooltipTypeDepart(typeDepart)}
          </Fragment>
        ))}
      </SGText>
      {montantTotal === undefined ? (
        <SGSkeleton key="skeleton" title={false} />
      ) : (
        <SGBox key="box-montant">
          <>
            <SGText weight="600" size="xs">
              Revenus à la retraite
            </SGText>
            <SGText titlerender size="l">
              {` ${montantTotal}${" "}€ `}
            </SGText>
            <SGText weight="400">net/mois</SGText>
          </>
        </SGBox>
      )}
    </SGSpace>
  );
};
