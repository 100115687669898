import { FunctionComponent, useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SGAlert } from "sg-alert";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTitle } from "sg-typo";
import { setVientDeFaireReformeDA } from "store/dashboard/actions";
import { hasFetchedDashboard } from "store/dashboard/types";
import { goToStep4, resetParcours } from "store/parcoursRIS/action";
import {
  fetchParcoursRisUploadAccessible,
  updateParcoursRisUpload,
} from "store/parcoursRisUpload/actions";
import { ParcoursRisUploadState } from "store/parcoursRisUpload/types";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { Loader } from "website/components/atoms/Loader/Loader";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import { useResultatRis } from "website/components/hooks/useResultatRis";
import { AssuModal } from "website/components/molecules/AssuModal/AssuModal";
import { Faq } from "website/components/molecules/Faq/Faq";
import {
  HypothesisAnchor,
  HypothesisModal,
} from "website/components/molecules/HypothesisModal/HypothesisModal";
import { StepsAssu as Steps } from "website/components/molecules/StepsAssu/StepsAssu";
import { DASHBOARD, PARCOURS_RIS } from "website/utils/privateRoutes";
import { State } from "../../store/store";
import { ParcoursRISComplements } from "../components/organisms/ParcoursRIS/ParcoursRISComplements/ParcoursRISComplements";
import { ParcoursRISPreparation } from "../components/organisms/ParcoursRIS/ParcoursRISPreparation/ParcoursRISPreparation";
import { ParcoursRISProjection } from "../components/organisms/ParcoursRIS/ParcoursRISProjection/ParcoursRISProjection";
import { ParcoursRISResultats } from "../components/organisms/ParcoursRIS/ParcoursRISResultats/ParcoursRISResultats";
import { ParcoursRIS } from "../components/templates/ParcoursRIS/ParcoursRIS";

export const PARAM_RESULTAT = "resultat";
export const QUERY_PARAM_ETAPE = "etape";

export enum RIS_STEPS {
  PREPARATION,
  COMPLEMENT,
  PROJECTION,
  RESULTATS,
}

const ParcoursRISPage: FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const etapeSearchParams = urlSearchParams.get(QUERY_PARAM_ETAPE);

  const { updateResultatRis, hasUpdateResultatRis, hasAgesTries } =
    useResultatRis();

  // Mis à vrai après le reset du parcours
  const [showLoader, setShowLoader] = useState(true);
  const currentStep = useSelector<State, number>(
    (state) => state.parcoursRIS.currentStep
  );
  const parcoursRisUploadState: ParcoursRisUploadState = useSelector<
    State,
    ParcoursRisUploadState
  >((state) => state.parcoursRisUpload);
  const faqBodyList = [
    "parcoursRIS.ris",
    "parcoursRIS.ouTrouver",
    "parcoursRIS.formatRis",
    "parcoursRIS.fonctionnement",
    "parcoursRIS.sapiendo",
  ];
  const [showHypothesisModal, setShowHypothesisModal] = useState(false);

  const [exitPath, setExitPath] = useState<string>();
  const [showExitModal, setShowExitModal] = useState(false);
  const [unblock, setUnblock] = useState<() => void>(); // UnregisterCallback
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const dashboardState = useDashboardState();

  // initialisation du state à l'entrée du parcours pour l'étape 3
  usePersonalInformations();

  const blockStep = [RIS_STEPS.COMPLEMENT, RIS_STEPS.PROJECTION];

  const steps: FunctionComponent[] = [
    ParcoursRISPreparation,
    ParcoursRISComplements,
    ParcoursRISProjection,
    ParcoursRISResultats,
  ];
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const parcoursRISsteps = [
    intl.formatMessage({ id: "parcoursRIS.step.preparation" }),
    intl.formatMessage({ id: "parcoursRIS.step.complements" }),
    intl.formatMessage({ id: "parcoursRIS.step.projection" }),
    intl.formatMessage({ id: "parcoursRIS.step.resultat" }),
  ];

  function initUnblock() {
    // Bloquage du parcours pour confirmation
    return history.block(({ pathname }) => {
      // On ignore
      if (pathname !== PARCOURS_RIS) {
        // Sortie non autorisée
        setExitPath(pathname);
        setShowExitModal(true);
        history.push(PARCOURS_RIS);
      }

      return false;
    });
  }

  // En cas de rechargement de page ou de sortie du site
  useBeforeunload((event) => {
    if (!showExitModal && blockStep.includes(currentStep)) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    // Permet d'être redirigé vers l'étape 4 si le paramètre de l'url est "etape=resultat"
    if (
      !hasUpdateResultatRis &&
      etapeSearchParams === PARAM_RESULTAT &&
      hasFetchedDashboard(dashboardState) &&
      hasAgesTries
    ) {
      // Permet de récupérer les informations de la simulation RIS
      updateResultatRis();
      dispatch(goToStep4());
    }
    if (hasUpdateResultatRis && currentStep === RIS_STEPS.RESULTATS) {
      setShowLoader(false);
    }
  }, [dashboardState, currentStep, hasUpdateResultatRis, hasAgesTries]);

  // Réinitialisation du parcours
  useEffect(() => {
    // Permet de ne pas afficher la pop-in du départ anticipé
    dispatch(setVientDeFaireReformeDA(false));

    dispatch(
      updateParcoursRisUpload({
        ...parcoursRisUploadState.parcoursRisUpload,
        processed: false,
      })
    );
  }, []);

  useEffect(() => {
    if (etapeSearchParams !== PARAM_RESULTAT) {
      dispatch(resetParcours());
      dispatch(fetchParcoursRisUploadAccessible());
    }
  }, [etapeSearchParams]);

  useEffect(() => {
    // Si on n'a pas sur l'étape 'resultat'
    if (currentStep !== RIS_STEPS.RESULTATS) {
      if (parcoursRisUploadState.accessible) {
        setShowLoader(false);
      } else if (parcoursRisUploadState.accessible === false) {
        window.location.href = `#${DASHBOARD}`;
      }
    }
  }, [parcoursRisUploadState.accessible, currentStep]);

  /**
   * Confirmation de l'abandon du parcours
   */
  const confirmExit = () => {
    if (unblock) {
      unblock();
    }
    if (exitPath) {
      history.push(exitPath);
    }
    setShowExitModal(false);
  };

  /**
   * Confirmation de l'annulation
   */
  const cancelExit = () => {
    setShowExitModal(false);
  };

  useEffect(() => {
    // Déverrouillage à la fin du parcours
    // reset est false à l'init du composant, pour éviter d'afficher la Step resultat
    if (!showLoader && currentStep === RIS_STEPS.RESULTATS && unblock) {
      unblock();
    }

    // Verrouillage le parcours
    if (
      !showLoader &&
      currentStep === Math.min(...blockStep) &&
      unblock === undefined
    ) {
      setUnblock(() => initUnblock());
    }
  }, [showLoader, currentStep]);

  useEffect(() => {
    setShowAlert(
      !possedeDroitsActionUtilisateur(supervisionState) &&
        dashboardState.dashboard?.risDate === undefined &&
        currentStep === RIS_STEPS.PREPARATION
    );
  }, [dashboardState, supervisionState, currentStep]);

  return (
    <SGLayout tagName="div">
      {showLoader ? (
        <SGContent>
          <Loader />
        </SGContent>
      ) : (
        <>
          <SGContent disableautomargin={showAlert && !isPhone}>
            <SGGridRow justify="center">
              {showAlert && (
                <SGGridCol span={12}>
                  <SGBox margin={{ bottom: "md" }}>
                    <SGAlert
                      disableautomargin
                      icon={
                        <SGIcon component={<SGAvenirStatusInfo />} size="m" />
                      }
                      message={intl.formatMessage({
                        id: "parcoursRIS.alerte.supervision",
                      })}
                      span={10}
                      type="info"
                    />
                  </SGBox>
                </SGGridCol>
              )}
              <SGGridCol span={12}>
                <SGTitle textalign={isPhone ? "left" : "center"} level={1}>
                  {intl.formatMessage({ id: "parcoursRIS.header" })}
                </SGTitle>
              </SGGridCol>
              <SGGridCol span={12}>
                <Steps currentStep={currentStep} steps={parcoursRISsteps} />
              </SGGridCol>
            </SGGridRow>
          </SGContent>
          <SGLayout
            background={
              !isPhone && blockStep.includes(currentStep) ? undefined : "light"
            }
          >
            <SGHeader disableautomargin />
            <SGContent disableautomargin>
              <SGBox margin={{ bottom: "sm" }}>
                <>
                  <ParcoursRIS currentStep={currentStep} steps={steps} />
                  {currentStep !== RIS_STEPS.RESULTATS && (
                    <SGGridRow justify="center">
                      <SGGridCol span={12} xxl={8}>
                        <SGBox
                          margin={{ left: "xl", right: "xl", bottom: "xl" }}
                        >
                          <Faq
                            bodyFaq={faqBodyList}
                            openDefaultKeys={[""]}
                            isMainAccordionOpen={
                              currentStep === RIS_STEPS.PREPARATION
                            }
                            hideMainAccordion={false}
                            setShowHypothesisModal={setShowHypothesisModal}
                          />
                        </SGBox>
                        {showHypothesisModal && (
                          <HypothesisModal
                            setVisible={setShowHypothesisModal}
                            visible={showHypothesisModal}
                            anchor={HypothesisAnchor.RSI}
                            closeSecondGroup
                          />
                        )}
                      </SGGridCol>
                    </SGGridRow>
                  )}
                </>
              </SGBox>
            </SGContent>
          </SGLayout>
        </>
      )}
      <AssuModal
        type="exitRis"
        visible={showExitModal}
        closable={false}
        onClickLeftButton={cancelExit}
        onClickRightButton={confirmExit}
        isSubtitle
      />
    </SGLayout>
  );
};

export { ParcoursRISPage };
