import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedMesRendezVous } from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGMediaQuery } from "sg-media-query";
import { SGBox, SGEqualHeight, SGEqualHeightItem } from "sg-space";
import { SGSticky } from "sg-sticky";
import { AccountState } from "store/account/types";
import { updateAppointment } from "store/appointment/actions";
import { AppointmentState } from "store/appointment/types";
import { updateAndReloadPrefs } from "store/dashboard/actions";
import { PathType, User, hasFetchedDashboard } from "store/dashboard/types";
import { getIdTrackingType } from "store/personnalisation/dashboard/types";
import { State } from "store/store";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { getLienArticle, getLienThematique } from "website/components/hooks/articles";
import { useDashboardLastPathDoneAndMessage } from "website/components/hooks/dashboard/useDashboardLastPathDoneAndMessage";
import { useDashboardProgression } from "website/components/hooks/dashboard/useDashboardProgression";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { usePersonnalisationDashboard } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { PAGE_TYPE_GESTION } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { InfoAssuModal } from "website/components/molecules/InfoAssuModal/InfoAssuModal";
import { UnderstandSimulationModal } from "website/components/molecules/UnderstandSimulationModal/UnderstandSimulationModal";
import { CTA, SideBarChatBot } from "website/components/organisms/Dashboard/SideBarChatBot/SideBarChatBot";
import {
    ARTICLES,
    BESOIN_EPARGNE,
    MES_SERVICES,
    MON_PLAN,
    PARCOURS_REVENUS_COMPLEMENTAIRES,
    PARCOURS_RIS,
    SIMULATEUR_ECONOMIES_IMPOTS
} from "website/utils/privateRoutes";
import { DashboardPerso } from "./DashboardPerso";

export enum MessageType {
    A = "readMessageADate",
    B = "readMessageBDate",
    C1 = "readMessageC1Date",
    C2 = "readMessageC2Date",
    C3 = "readMessageC3Date",
    D = "readMessageDDate",
    E = "readMessageEDate",
    F = "readMessageFDate",
    Z = "readMessageZDate",
}

export const Dashboard: FunctionComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { trackPage, trackClick, trackDownload } = useTracking();

    const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);
    const estSupervisionActive: boolean = useSelector<State, boolean>((state) => state.supervision.estActive);

    const [savedObjectifRevenu, setSavedObjectifRevenu] = useState<number | undefined>();
    const [ctaToDisplay, setCtaToDisplay] = useState<CTA[]>([]);
    const [animateCTA, setAnimateCTA] = useState<boolean>(false);
    const [showLegalNoticesModal, setShowLegalNoticesModal] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showCTA, setShowCTA] = useState(false);
    const [showUnderstandSimulationModal, setShowUnderstandSimulationModal] = useState(false);
    const [toggleButtonText, setToggleButtonText] = useState(true);
    const [tablesModal, setTablesModal] = useState<string[][]>();
    const [appointmentDate, setAppointmentDate] = useState("");
    const [appointmentTime, setAppointmentTime] = useState("");
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    // Custom hooks
    const dashboardState = useDashboardState();
    const { dashboard } = usePersonnalisationDashboard();
    const pourcentage = useDashboardProgression();
    const { lastPathDone, messageType } = useDashboardLastPathDoneAndMessage();
    const openSGAgenda = useSGAgenda();
    const appointmentState: AppointmentState = useSelector<State, AppointmentState>((state) => state.appointment);

    // Booléen qui permet de savoir si le parcours SEI est déjà effectué
    const {
        parcoursSEIActif,
        disponibiliteSGAgenda,
        disponibiliteParcoursRevenusComplementaires,
    } = useFeatures();

    const handleOnClickRdv = () => {
        if (disponibiliteSGAgenda) {
            openSGAgenda();
        } else {
            // SGAgenda désactivé, on affiche un message d'erreur
            setShowErrorModal(true);
        }
    };

    const messageSEI = {
        text: "sei",
        redirectTo: () => {
            history.push(SIMULATEUR_ECONOMIES_IMPOTS);
        },
        trackKey: "simulateur-economies-impots",
        hideCard: !parcoursSEIActif,
    };

    const messagesChatBotList = {
        [MessageType.A]: [
            { text: "comprendreResultat", redirectTo: setShowUnderstandSimulationModal, trackKey: "comprendre-resultat" },
            { text: "fiabiliser", redirectTo: () => {
                history.push(PARCOURS_RIS);
                }, trackKey: "parcours-ris" },
            {
                text: "comprendreRetraite",
                redirectTo: () => {
                    history.push(getLienArticle("retraite-sans-jargon"));
                },
                trackKey: "article-retraite-sans-jargon",
            },
            messageSEI,
        ],
        [MessageType.B]: [
            { text: "comprendreResultatRis", redirectTo: setShowUnderstandSimulationModal, trackKey: "comprendre-resultat" },
            {
                text: "estimerRevenus",
                redirectTo: () => {
                    history.push(PARCOURS_REVENUS_COMPLEMENTAIRES);
                },
                trackKey: "parcours-patrimoine",
                hideCard: !disponibiliteParcoursRevenusComplementaires,
            },
            {
                text: "comprendreReleveRis",
                redirectTo: () => {
                    history.push(getLienArticle("comprendre-ris"));
                },
                trackKey: "article-comprendre-ris",
            },
            messageSEI,
        ],
        [MessageType.C1]: [
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(BESOIN_EPARGNE);
                },
                trackKey: "parcours-epargner",
            },
            {
                text: "article.idees",
                redirectTo: () => {
                    history.push(getLienArticle("idees-preparer-retraite"));
                },
                trackKey: "article-idees-preparer-retraite",
            },
            messageSEI,
        ],
        [MessageType.C2]: [
            { text: "fiabiliser", redirectTo: () => {
                history.push(PARCOURS_RIS);
                }, trackKey: "parcours-ris" },
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(BESOIN_EPARGNE);
                },
                trackKey: "parcours-epargner",
            },
            messageSEI,
        ],
        [MessageType.C3]: [
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(BESOIN_EPARGNE);
                },
                trackKey: "parcours-epargner",
            },
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "accompagnerExperts",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
        [MessageType.D]: [
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(BESOIN_EPARGNE);
                },
                trackKey: "parcours-epargner",
            },
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "accompagnerExperts",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
        [MessageType.E]: [
            {
                text: "definirPlan",
                redirectTo: () => {
                    history.push(MON_PLAN);
                },
                trackKey: "definir-plan",
            },
            {
                text: "agendaRendezVous",
                redirectTo: estSupervisionActive ? undefined : handleOnClickRdv,
                trackKey: "rdv",
                complementTracking: { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" },
            },
            {
                text: "strategies",
                redirectTo: () => {
                    history.push(getLienThematique("strategie-retraite"));
                },
                trackKey: "article-strategie-retraite",
            },
            messageSEI,
        ],
        [MessageType.F]: [
            {
                text: "agenda",
                redirectTo: estSupervisionActive ? undefined : handleOnClickRdv,
                trackKey: "rdv",
                complementTracking: { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" },
            },
            {
                text: "home",
                redirectTo: () => {
                    history.push(ARTICLES);
                },
                trackKey: "articles-home",
            },
            {
                text: "services",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
        [MessageType.Z]: [
            {
                text: "article.idees",
                redirectTo: () => {
                    history.push(getLienArticle("idees-preparer-retraite"));
                },
                trackKey: "article-idees-preparer-retraite",
            },
            {
                text: "agenda",
                redirectTo: estSupervisionActive ? undefined : handleOnClickRdv,
                trackKey: "rdv",
                complementTracking: { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" },
            },
            {
                text: "accompagnerExperts",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
    };
    const tablesModalList = {
        [MessageType.A]: [["statutPro", "carriere", "nbrEnfants", "revenusPro"]],
        [MessageType.B]: [["statutPro", "carriere"], ["projections"]],
    };

    const onHideAppointmentModal = () => {
        dispatch(updateAppointment({ ...appointmentState.appointment, appointmentJustCreated: false }));
    };

    useEffect(() => {
        if (dashboard) {
            trackPage("tableau-de-bord", "accueil", PAGE_TYPE_GESTION, "", "", {page_field_3: getIdTrackingType(dashboard)});
        }
    }, [dashboard]);

    useEffect(() => {
        if (messageType) {
            setCtaToDisplay(messagesChatBotList[messageType]);

            switch (messageType) {
                case MessageType.A:
                    setTablesModal(tablesModalList[messageType]);
                    break;
                case MessageType.B:
                    setTablesModal(tablesModalList[messageType]);
                    break;
                default:
            }
        }
    }, [messageType, parcoursSEIActif]);

    useEffect(() => {
        if (hasFetchedDashboard(dashboardState)) {
            // Affichage des préférences utilisateur (age de départ choisi et objectif de revenu saisi précedemment)
            if (
                dashboardState.dashboard.monthlyIncomeGoal !== undefined &&
                dashboardState.dashboard.monthlyIncomeGoal >= 0 &&
                (savedObjectifRevenu === undefined || dashboardState.dashboard.monthlyIncomeGoal !== savedObjectifRevenu)
            ) {
                setSavedObjectifRevenu(dashboardState.dashboard.monthlyIncomeGoal);
            }

            setShowLegalNoticesModal(dashboardState.dashboard.legalNoticesAcceptanceDate === undefined);
        }
    }, [dashboardState]);

    useEffect(() => {
        if (appointmentState.appointment !== undefined) {
            if (appointmentState.appointment.date !== undefined) {
                setAppointmentDate(new Date(appointmentState.appointment.date).toLocaleDateString());
            }
            if (appointmentState.appointment.time !== undefined) {
                setAppointmentTime(appointmentState.appointment.time);
            }
            if (appointmentState.appointment.appointmentJustCreated !== undefined) {
                setShowAppointmentModal(appointmentState.appointment.appointmentJustCreated);
            }
        }
    }, [appointmentState]);

    useEffect(() => {
        if (!showLegalNoticesModal && messageType) {
            if (dashboardState.dashboard[messageType]) {
                setAnimateCTA(false);
            } else {
                saveReadMessageXToDb(messageType);
                setAnimateCTA(true);
            }
            setShowCTA(true);
        }
    }, [showLegalNoticesModal, messageType]);

    const saveReadMessageXToDb = (type: MessageType) => {
        const req: User = {
            [type]: new Date(),
        };
        dispatch(updateAndReloadPrefs(req));
    };

    useEffect(() => {
        const intervalID = setInterval(() => {
            setToggleButtonText((toggle) => !toggle);
        }, 7000);

        return () => clearInterval(intervalID);
    }, []);

    return (
        <>
            <SGMediaQuery minwidth={0} maxwidth="lg">
                <SGLayout background="light">
                    <SGBox padding={{left: "sm", right: "sm", top: "sm", bottom: "md"}}>
                        <DashboardPerso />
                     </SGBox>
                </SGLayout>
                {savedObjectifRevenu !== undefined && (
                    <SGSticky>
                        <SGContent disableautomargin>
                            <SGBox alignchildren={["center"]}>
                                <BoutonSGAgenda
                                    type="primary"
                                    icon={<SGIcon component={<SGAvenirStrokedMesRendezVous />} currentcolor />}
                                    onClick={() => {
                                        trackClick("clic-sur-sticky-contacter-mon-conseiller", { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" });
                                        handleOnClickRdv();
                                    }}
                                >
                                    <FormattedMessage id={`dashboard.button.contacter.${toggleButtonText ? "conseiller" : "rendez-vous"}`} />
                                </BoutonSGAgenda>
                            </SGBox>
                        </SGContent>
                    </SGSticky>
                )}
            </SGMediaQuery>
            <SGMediaQuery minwidth="xl">
                <SGBox span={12}>
                    <SGEqualHeight>
                        <SGGridRow gutter={[40, 0]}>
                            <SGGridCol span={4} textalign="left">
                                <SGLayout background="light" stretch>
                                    <SGEqualHeightItem>
                                        <SGBox margin={{right: "sm", left: "sm"}}>
                                            <SideBarChatBot
                                                ctaToDisplay={ctaToDisplay}
                                                animateCTA={animateCTA}
                                                lastPathDone={lastPathDone}
                                                pourcentage={pourcentage}
                                                user={accountState.account}
                                                showCTA={showCTA}
                                            />
                                        </SGBox>
                                    </SGEqualHeightItem>
                                </SGLayout>
                            </SGGridCol>
                            <SGGridCol span={8}>
                                <SGEqualHeightItem>
                                    <SGBox key="box-temporaire-margin-space" padding={{ top: "lg", bottom: "md" }}>
                                        <DashboardPerso />
                                    </SGBox>
                                </SGEqualHeightItem>
                            </SGGridCol>
                        </SGGridRow>
                    </SGEqualHeight>
                </SGBox>
                {lastPathDone && [PathType.ONBOARDING, PathType.RIS].includes(lastPathDone) && tablesModal && (
                    <UnderstandSimulationModal
                        visible={showUnderstandSimulationModal}
                        setVisible={setShowUnderstandSimulationModal}
                        lastPathDone={lastPathDone}
                        tables={tablesModal}
                    />
                )}
                <InfoAssuModal
                    onHide={onHideAppointmentModal}
                    showInfoModal={showAppointmentModal}
                    text="popin.info.appointment.text"
                    tranform={{ date: appointmentDate, time: appointmentTime, linebreak: <br /> }}
                    trackKey="popin-confirmation-rdv"
                    title="modal.error.info.title"
                    buttonPrimary="modal.error.button"
                />
                {showErrorModal && <ErrorModal visible={showErrorModal} setVisible={setShowErrorModal} description="modal.error.message.indisponible" />}
            </SGMediaQuery>
        </>
    )
};
