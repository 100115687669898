import { ChangeEvent, FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGRadio, SGRadioGroup } from "sg-radio";
import { SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { DashboardAgeType, getIdTracking } from "store/dashboard/types";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CarteAgeContenu } from "../CarteAgeContenu/CarteAgeContenu";

interface PensionSituationProps {
  changeSelectedAgeRetirement: (type: DashboardAgeType) => void;
  accessible?: boolean;
}

export const PensionSituation: FunctionComponent<PensionSituationProps> = ({
  changeSelectedAgeRetirement,
  accessible = true,
}) => {
  const { agesTries } = useSelectedRetirementAge();
  const { trackClick } = useTracking();

  const [ageSelectionne, setAgeSelectionne] = useState<number>();

  const onClickValider = () => {
    trackClick("clic-sur-valider");
    if (agesTries && ageSelectionne !== undefined) {
      // Bouton désactivé, sinon
      changeSelectedAgeRetirement(agesTries[ageSelectionne].type);
    }
  };

  return (
    <SGSpace direction="vertical" disableautomargin size="xl">
      <SGTitle key="titre" level={2} textalign="center">
        <FormattedMessage id="tunnel.pension.title" />
      </SGTitle>

      <SGGridRow key="gridrow" justify="center" gutter={[0, 24]}>
        <SGGridCol span={12} md={8}>
          <SGRadioGroup
            key="radios"
            buttonstyle="tile"
            layout="column"
            value={ageSelectionne}
            onChange={(event: ChangeEvent<HTMLButtonElement>) => {
              const choix = parseInt(event.target.value);
              if (agesTries && agesTries.length > choix) {
                const ageType = agesTries[choix].type;
                trackClick(`clic-sur-card-${getIdTracking(ageType)}`);
                setAgeSelectionne(choix);
              }
            }}
            disabled={!accessible}
          >
            {agesTries?.map((age, index) => (
              <SGRadio key={age.type} value={index}>
                <CarteAgeContenu age={age} />
              </SGRadio>
            ))}
          </SGRadioGroup>
        </SGGridCol>
        <SGGridCol />
      </SGGridRow>

      <SGButtonGroup key="buttons" align="center">
        <BoutonSupervision
          onClick={onClickValider}
          disabled={ageSelectionne === undefined}
        >
          <FormattedMessage id="tunnel.buttons.last" />
        </BoutonSupervision>
      </SGButtonGroup>
    </SGSpace>
  );
};
