import { init as initApm } from "@elastic/apm-rum";
import { ApmRoute } from "@elastic/apm-rum-react";
import { FunctionComponent, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { Store } from "redux";
import { SGLayout } from "sg-layout";
import { AccountState, isLoggedIn } from "store/account/types";
import { hasFetchedDashboard } from "store/dashboard/types";
import { ErrorState, ErrorType } from "store/error/types";
import { importAssetAndMembers, importLoans } from "store/import/actions";
import { ImportState } from "store/import/types";
import {
  isWebView
} from "store/import/utils";
import { logout } from "store/login/actions";
import { LoginState } from "store/login/types";
import { Family } from "store/members/types";
import { State } from "store/store";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import {
  BddfTms,
  LoadingStatus,
  TcVars,
  TcVarsEvent,
} from "website/components/hooks/tracking/types";
import { Footer } from "website/components/organisms/Utils/Footer/Footer";
import { Header } from "website/components/organisms/Utils/Header/Header";
import {
  DASHBOARD,
  privateRoutes
} from "website/utils/privateRoutes";
import {
  INTROUVABLE,
  LOGIN,
  MAINTENANCE,
  publicRoutes
} from "website/utils/publicRoutes";
import { PrivateRoute } from "website/utils/routes/PrivateRoute";
import "./App.scss";
import { useAccount } from "./components/hooks/useAccount";
import { useAppErrors } from "./components/hooks/useAppErrors";
import { useAppInit } from "./components/hooks/useAppInit";
import { useFeatures } from "./components/hooks/useFeatures";
import { useMenuContextuel } from "./components/hooks/useMenuContextuel";
import { ManagementModalsAuto } from "./components/organisms/ManagementModalsAuto/ManagementModalsAuto";

declare global {
  interface Window {
    bddfTms: BddfTms;
    // eslint-disable-next-line camelcase
    tc_vars: Partial<TcVars>;
    // eslint-disable-next-line camelcase
    tc_vars_array: TcVarsEvent[];
    // eslint-disable-next-line camelcase
    native_interaction_method: any;
    env: { [key: string]: string };
    Cypress?: boolean;
    app: {
      setHorizon: (horizon: number) => void;
      setProjectAmount: (projectAmount: number) => void;
      setMyRetirementAge: (retirementAge: number) => void;
      setPartnerRetirementAge: (retirementAge: number) => void;
      updateProjectAndGetFeasibility: () => void;
    };
    store: Store;
    // Pour iOS
    MSStream: any;
  }
}

export const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { pathname, hash, key } = useLocation();

  const account = useAccount();

  const hasFetchedAccount = useSelector<State, boolean>(
    (state) => state.account.hasFetched
  );
  const errorState: ErrorState = useSelector<State, ErrorState>(
    (state) => state.error
  );
  const loginState: LoginState = useSelector<State, LoginState>(
    (state) => state.login
  );
  const importData: ImportState = useSelector<State, ImportState>(
    (state) => state.importData
  );

  const accountState = useSelector<State, AccountState>((state) => state.account);

  const { loadingScrapping, loadingState } = useAppInit();
  useAppErrors();
  const features = useFeatures();
  useMenuContextuel();

  // Logique appelée dans le cas où l'utilisateur est inactif pendant 20 min.
  // On renvoie vers la page de login si l'utilisateur n'est pas déjà sur une page publique
  const handleOnIdle = () => {
    if (
      !publicRoutes.map((publicRoute) => publicRoute.path).includes(pathname)
    ) {
      dispatch(logout());
      document.title = intl.formatMessage({ id: "login.session.expired" });
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 20,
    onIdle: handleOnIdle,
  });

  // Logique appelée lors de la déconnexion. Elle vide le cache de session et renvoie l'utilisateur vers l'URL de déconnexion de l'application
  useEffect(() => {
    if (loginState.forceLogOut && loginState.logoutRedirection) {
      localStorage.removeItem("clientId");
      localStorage.removeItem("login");
      sessionStorage.clear();
      window.location.href = loginState.logoutRedirection;
    }
  }, [loginState.forceLogOut, loginState.logoutRedirection]);

  // Rechargement de l'account en cas de rafraichissement d'une page privée + gestion du titre/favicon
  useEffect(() => {
    // Gestion du favicon
    const link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (link !== null) {
      link.href =
        window.env.REACT_APP_INSTANCE === "default"
          ? "favicon.png"
          : "favicon-sg.png";
    }

    // Gestion du title
    document.title = intl.formatMessage({ id: "app.title" });

    // Initialisation de l'agent RUM
    initApm({
      serviceName: "MonCompagnonRetraite",
      serverUrl: window.env.REACT_APP_APM_URL,
      environment: window.env.REACT_APP_APM_ENV,
      active: window.env.REACT_APP_APM_ACTIVE === "true",
      distributedTracingOrigins: [window.env.REACT_APP_API_URL],
      propagateTracestate: true,
    });
  }, []);

  // Rechargement des loans/assets SG depuis le sessionStorage en cas de rafraichissement d'une page privée
  useEffect(() => {
    if (hasFetchedAccount && window.env.REACT_APP_USE_SSO === "true") {
      const assetsAndLoans = sessionStorage.getItem("assetsAndLoans");
      // Code segment non récupéré, on relance l'import
      // alreadyImported reste à false si on n'est pas passé par /auth
      if (
        !importData.alreadyImported &&
        account.tunnelCompleted &&
        account.codeSegmentMarcheNSM === undefined &&
        features.disponibiliteParcoursRevenusComplementaires !== undefined
      ) {
        dispatch(
          importAssetAndMembers(features.disponibiliteParcoursRevenusComplementaires)
        );
      }
      if (
        !importData.alreadyImported &&
        account.tunnelCompleted &&
        assetsAndLoans !== null
      ) {
        dispatch(importLoans(JSON.parse(assetsAndLoans)));
      }
    }
  }, [hasFetchedAccount, account, features.disponibiliteParcoursRevenusComplementaires]);

  /**
   * Gestion des ancres #
   */
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 500);
    }
  }, [pathname, hash, key]); // do this on route change

  const renderAllRoutes = () => (

    <Switch>
      {/* On vérifie d'abord si l'URL matche une URL publique */}
      {publicRoutes
        .filter(
          (props) =>
            !props.hiddenWhenLoginDisabled ||
            window.env.REACT_APP_DISPLAY_LOGIN === "true"
        )
        .map((props) => (
          <ApmRoute key={props.path} exact {...props} />
        ))}

      {/* Une fois l'account récupéré, on vérifie si l'URL matche une URL privée */}
      {hasFetchedAccount &&
        privateRoutes.map((props) => (
          <PrivateRoute key={props.path} exact {...props} features={features}/>
        ))}

      {/* Dans le cas où l'on ne matche aucun path, on redirige vers la page coach  */}
      {/* L'utilisation de PrivateRoute permet de gérer le cas où l'utilisateur n'est pas connecté ou n'a pas fini le tunnel  */}

      {/* Redirection de "/" vers le dashboard */}
      {hasFetchedAccount && (
        <PrivateRoute
          path="/"
          exact
          component={() => <Redirect to={DASHBOARD} />}
          features={features}
        />
      )}
      {/* C'est ici que l'on va rediriger vers la page 404 */}
      {hasFetchedAccount && (
        <PrivateRoute
          path="*"
          component={() => <Redirect to={INTROUVABLE} />}
          features={features}
        />
      )}
      {!hasFetchedAccount && !sessionStorage.getItem("token") && (
        <PrivateRoute path="*" component={() => <Redirect to={LOGIN} />} features={features}/>
      )}
    </Switch>
  );

  if (loadingScrapping && isLoggedIn(accountState)) {
    return (
      <div>
        <div className="loader__wrapper">
          <div className="loader__loader" />
          Connexion en cours
        </div>
      </div>
    );
  }

  if (!hasFetchedAccount || loginState.forceLogOut) {
    return <SGLayout>{renderAllRoutes()}</SGLayout>;
  }

  return (
        <>
          {!loadingState ? <Header /> : null}
          {loadingState && (
          <div>
            <div className="loader__wrapper">
              <div className="loader__loader" />
              Chargement en cours
            </div>
          </div>
        )}
          <SGLayout tagName="main" className="app__layout">
            {renderAllRoutes()}
          </SGLayout>
          {!loadingState && !isWebView() ? <Footer /> : null}
          {(!errorState.hasError && pathname !== MAINTENANCE) &&
            // Pas de modale automatique sur ces parcours, ou en cas d'erreur
            <ManagementModalsAuto />
          }
        </>
  );
};
