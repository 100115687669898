import { ReactNodeLike } from "prop-types";
import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { Breakpoint } from "website/utils/composantsSGC/types";
import { getTextStrong, getTextSup } from "website/utils/formatting/ValuesFormattedMessage";
import { NomPageEnum, parseLink } from "../NosSolutions/utils";

export interface IconeBlocPicto {
  key: string;
  icone: ReactNodeLike;
}

interface BlocPictoProps {
  icones: IconeBlocPicto[];
  nom: NomPageEnum;
  spanBreakpoint: Breakpoint;
}

const BlocPicto: FunctionComponent<BlocPictoProps> = (
  props: BlocPictoProps
) => {
  const { icones, nom, spanBreakpoint } = props;

  const intl = useIntl();
  const openLink = useOpenLink();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <SGSpace direction="vertical" size="md" disableautomargin>
      <SGTitle
        key="titre"
        level={2}
        visuallevel={isPhone ? 2 : 3}
        textalign="center"
      >
        {intl.formatMessage({
          id: `${nom}.section.pointsCles.titre`,
        })}
      </SGTitle>
      <SGGridRow key="row" justify="center" gutter={[24, 24]}>
        {icones.map((icone, index) => (
          <SGGridCol key={`icone-${icone.key}`} span={12} {...spanBreakpoint} textalign="center">
            <SGSpace direction="vertical" size="sm" disableautomargin>
              <SGIcon key="icone" component={icone.icone} currentcolor />
              <SGText key="text" size={isPhone ? "m" : "s"}>
                {intl.formatMessage(
                  {
                    id: `${nom}.section.pointsCles.bloc.${index}`,
                  },
                  {
                    b: getTextStrong,
                    sup: getTextSup,
                    link: (word: string) => parseLink(word[0], openLink),
                  }
                )}
              </SGText>
            </SGSpace>
          </SGGridCol>
        ))}
      </SGGridRow>
    </SGSpace>
  );
};

export { BlocPicto };
