import { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedMesRendezVous } from "sg-icon-pack-base";
import { SGContent } from "sg-layout";
import { SGMediaQuery } from "sg-media-query";
import { SGSticky } from "sg-sticky";
import { SGTitle } from "sg-typo";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "../ErrorModal/ErrorModal";

export const StickySGAgenda: FunctionComponent = () => {
  const { trackClick } = useTracking();
  const openSGAgenda = useSGAgenda();
  const { disponibiliteSGAgenda } = useFeatures();

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const handleOnClickRdv = () => {
    trackClick(`sticky::clic-sur-contacter-mon-conseiller`);
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  return (
    <>
      <SGSticky>
        <SGContent disableautomargin>
          <SGGridRow align="middle" justify="center" gutter={[0, 0]}>
            <SGMediaQuery minwidth="sm">
              <SGGridCol flex="1 0 0%">
                <SGTitle level={2} visuallevel={4}>
                  <FormattedMessage id="sticky.titre" />
                </SGTitle>
              </SGGridCol>
            </SGMediaQuery>
            <SGGridCol flex="0 0 0%">
              <BoutonSGAgenda
                disableautomargin
                type="primary"
                icon={
                  <SGIcon
                    component={<SGAvenirStrokedMesRendezVous />}
                    currentcolor
                  />
                }
                onClick={handleOnClickRdv}
              >
                <FormattedMessage id="sticky.bouton" />
              </BoutonSGAgenda>
            </SGGridCol>
          </SGGridRow>
        </SGContent>
      </SGSticky>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </>
  );
};
