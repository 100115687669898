import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { SGButtonGroup } from "sg-button";
import { SGDrawer } from "sg-drawer";
import { useMediaQuery } from "sg-media-query";
import { SGRadio, SGRadioGroup } from "sg-radio";
import { SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { DashboardAge } from "store/dashboard/types";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CarteAgeContenu } from "website/components/organisms/CarteAgeContenu/CarteAgeContenu";

interface DrawerAgeProps {
  selectedRetirementAge?: DashboardAge;
  setSelectedRetirementAge: (retirementAge: DashboardAge) => void;
  agesDepartRetraite: DashboardAge[];
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
}

const DrawerAge: FunctionComponent<DrawerAgeProps> = (
  props: DrawerAgeProps
) => {
  const {
    agesDepartRetraite,
    selectedRetirementAge,
    setSelectedRetirementAge,
    showDrawer,
    setShowDrawer,
  } = props;

  const [ageSelectionne, setAgeSelectionne] = useState<number>();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { trackDrawer, trackClick } = useTracking();

  useEffect(() => {
    if (showDrawer) {
      trackDrawer("drawer-mon-age-de-depart");
    }
  }, [showDrawer]);

  useEffect(() => {
    if (selectedRetirementAge) {
      setAgeSelectionne(
        agesDepartRetraite.findIndex(
          (age) => age.type === selectedRetirementAge.type
        )
      );
    }
  }, [selectedRetirementAge, agesDepartRetraite]);

  const onClose = () => {
    trackClick("drawer-mon-age-de-depart::clic-sur-fermer");
    setShowDrawer(false);
  };

  const onClick = () => {
    trackClick("drawer-mon-age-de-depart::clic-sur-valider");
    if (ageSelectionne !== undefined) {
      setSelectedRetirementAge(agesDepartRetraite[ageSelectionne]);
    }
    setShowDrawer(false);
  };

  return (
    <SGDrawer
      placement={isPhone ? "bottom" : "right"}
      visible={showDrawer}
      onClose={onClose}
    >
      <SGSpace
        direction="vertical"
        size={isPhone ? "md" : "lg"}
        disableautomargin
      >
        <SGTitle key="titre" level={2} visuallevel={isPhone ? 2 : 3}>
          Choisir mon âge de départ à la retraite
        </SGTitle>
        <SGRadioGroup
          key="radios"
          buttonstyle="tile"
          layout="column"
          value={ageSelectionne}
          onChange={(event: ChangeEvent<HTMLButtonElement>) => {
            const choix = parseInt(event.target.value);
            setAgeSelectionne(choix);
          }}
        >
          {agesDepartRetraite?.map((age, index) => (
            <SGRadio key={age.type} value={index}>
              <CarteAgeContenu age={age} />
            </SGRadio>
          ))}
        </SGRadioGroup>
        <SGButtonGroup key="bouton" align="center">
          <BoutonSupervision onClick={onClick}>Valider</BoutonSupervision>
        </SGButtonGroup>
      </SGSpace>
    </SGDrawer>
  );
};

export { DrawerAge };
