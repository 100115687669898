import { PropsWithChildren } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGText, SGTitle } from "sg-typo";
import { Image } from "website/components/atoms/Image/Image";

interface CarteCliquableProps {
  idTitre: string;
  idTexte: string;
  src: string;
  alt: string;
  onClick: () => void;
  lien?: string;
}

export const CarteCliquable = ({
  onClick,
  lien,
  idTitre,
  idTexte,
  src,
  alt,
}: PropsWithChildren<CarteCliquableProps>) => {
  const intl = useIntl();
  const titre = `${intl.formatMessage({ id: idTitre })}`;
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <SGCard
      clickable
      coversize="sm"
      layout="row"
      coverposition="leftCenter"
      cover={<Image alt={alt} src={src} />}
      actions={[
        lien ? (
          <SGLink
            href={lien}
            icon={<SGIcon component={<SGAvenirNavFleche />} />}
            iconside="right"
            type="cta"
            onClick={onClick}
            ariaLabel={`Rediriger vers ${titre}`}
          />
        ) : (
          <SGButton
            icon={<SGIcon component={<SGAvenirNavFleche />} />}
            type="icon"
            onClick={onClick}
            ariaLabel={`Rediriger vers ${titre}`}
          />
        ),
      ]}
    >
      <SGTitle key="title" level={3} visuallevel={5}>
        <FormattedMessage id={idTitre} />
      </SGTitle>
      <SGText key="text" size="m" ellipsis={isPhone ? 2 : undefined}>
        <FormattedMessage id={idTexte} />
      </SGText>
    </SGCard>
  );
};
