import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SGContent, SGLayout } from "sg-layout";
import { SGLink } from "sg-link";
import { SGBox, SGSpace } from "sg-space";
import { SGSpinner } from "sg-spinner";
import { SGText, SGTitle } from "sg-typo";
import { updatePrefs } from "store/dashboard/actions";
import { User } from "store/dashboard/types";
import { State } from "store/store";
import { articlesAccesContenuMonObjectif } from "website/components/hooks/articles";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { AccesContenus } from "website/components/molecules/AccesContenus/AccesContenus";
import { SectionAllerPlusLoin } from "website/components/molecules/NosSolutions/SectionAllerPlusLoin";
import {
  NomPageEnum,
  ordreTuilesPage,
} from "website/components/molecules/NosSolutions/utils";
import {
  EffortEpargne,
  OngletEffortEpargne,
} from "website/components/organisms/Dashboard/EffortEpargne/EffortEpargne";
import { DASHBOARD } from "website/utils/privateRoutes";

const getTrackOnglet = (ongletCourant: OngletEffortEpargne) => {
  if (ongletCourant === OngletEffortEpargne.objectif) {
    return "mon-besoin-de-revenus";
  }

  return "mon-epargne";
};

export const MonObjectifPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const saveEvent = useTrackingEvent();
  const { trackPage, trackClick } = useTracking();
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const datePremierParcoursEpargner = useSelector<State, string | undefined>(
    (state) => state.dashboard.dashboard?.firstSavingPathDate
  );
  const premierChoixBesoins = useSelector<State, string | undefined>(
    (state) => state.dashboard.dashboard?.firstNeedsChoice
  );
  const { selectedRetirementAge } = useSelectedRetirementAge();

  const [ongletCourant, setOngletCourant] = useState<OngletEffortEpargne>();

  useEffect(() => {
    trackPage("mon-objectif", "accueil", "gestion-ecran");
    saveEvent(trackingEvent.OBJECTIF);
  }, []);

  useEffect(() => {
    // save first Saving Path Date
    if (datePremierParcoursEpargner === undefined) {
      const req: User = {
        firstSavingPathDate: new Date(),
      };
      dispatch(updatePrefs(req));
    }
  }, [datePremierParcoursEpargner]);

  useEffect(() => {
    const tab = urlSearchParams.get("tab");
    if (
      OngletEffortEpargne.objectif === tab ||
      OngletEffortEpargne.epargne === tab
    ) {
      setOngletCourant(tab);
    } else {
      setOngletCourant(
        premierChoixBesoins === OngletEffortEpargne.epargne
          ? OngletEffortEpargne.epargne
          : OngletEffortEpargne.objectif
      );
    }
  }, [urlSearchParams, premierChoixBesoins]);

  if (ongletCourant === undefined) {
    return <SGSpinner size="lg" />;
  }

  return (
    <SGLayout background="light">
      <SGContent disableautomargin>
        <SGTitle level={1}>
          <FormattedMessage id={`monObjectif.${ongletCourant}.titre`} />
        </SGTitle>
        <SGText size="l">
          <FormattedMessage id={`monObjectif.${ongletCourant}.sousTitre`} />
        </SGText>
        <SGSpace direction="vertical" align="center" size="xl">
          {selectedRetirementAge && ongletCourant && (
            <EffortEpargne
              key="effort-epargne"
              selectedRetirementAge={selectedRetirementAge}
              ongletDefaut={ongletCourant}
              onTabChange={setOngletCourant}
            />
          )}
          <SGLink
            key="lien-retour"
            type="primary"
            href={`#${DASHBOARD}`}
            onClick={() =>
              trackClick(
                "clic-sur-retourner-a-mon-espace-retraite",
                {
                  form_field_1: getTrackOnglet(ongletCourant),
                }
              )
            }
          >
            <FormattedMessage id="monObjectif.bouton.retour" />
          </SGLink>
        </SGSpace>

        <SGBox margin={{ top: "xl", bottom: "xl" }}>
          <SGSpace direction="vertical" size="xl">
            <SectionAllerPlusLoin
              key="accompagner"
              nom={NomPageEnum.MON_OBJECTIF}
              ordreTuilesPage={
                ordreTuilesPage[NomPageEnum.MON_OBJECTIF]
              }
            />

            <AccesContenus
              key="contenus"
              idMedias={articlesAccesContenuMonObjectif}
              nbSlides={3}
            />
          </SGSpace>
        </SGBox>
      </SGContent>
    </SGLayout>
  );
};
