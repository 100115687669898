import { ReactNodeLike } from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SGAvenirNavLoginOut, SGAvenirStrokedCompte } from "sg-icon-pack-base";
import { AccountState, isLoggedIn } from "store/account/types";
import { DashboardState } from "store/dashboard/types";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import {
  PARAM_RESULTAT,
  QUERY_PARAM_ETAPE,
} from "website/pages/ParcoursRISPage";
import {
  ARTICLES,
  BESOIN_EPARGNE,
  DASHBOARD,
  LOGOUT,
  MES_SERVICES,
  MODIFIER_PROFIL,
  MON_PLAN,
  NOS_SOLUTIONS_ASV,
  NOS_SOLUTIONS_PER,
  PARCOURS_REVENUS_COMPLEMENTAIRES,
  PARCOURS_RIS,
  REFORME_DEPART_ANTICIPE,
  SIMULATEUR_AVANT_APRES,
  SIMULATEUR_ECONOMIES_IMPOTS,
  TUNNEL,
  SYNTHESE_RETRAITE,
} from "website/utils/privateRoutes";
import { useFeatures } from "./useFeatures";
import { useParcoursPatrimoine } from "./useParcoursPatrimoine";

export enum MenuEnum {
  MENU_LOGOUT = "logout",
  PROFIL = "profil",
  MENU_DASHBOARD = "dashboard",
  OUTILS = "outils",
  PENSION = "pension",
  SEI = "sei",
  PATRIMOINE = "patrimoine",
  EPARGNE = "epargne",
  CONTENUS = "contenus",
  MENU_ARTICLES = "articles",
  MENU_MON_PLAN = "mon-plan",
  REFORME = "reforme",
  SOLUTIONS = "solutions",
  SERVICES = "services",
  ASV = "asv",
  PER = "per",
  SYNTHESE = "syntheseRetraite",
}
export interface MenuItem {
  key: string;
  /**
   * la path de redirection du menu
   */
  path?: string;
  /**
   * La liste des sous menus
   */
  sousMenus?: MenuItem[];
  /**
   * le parcours est accessible (feature flipping)
   */
  disponibiliteParcours?: boolean;
  /**
   * l'icone (le design est différent si on a une icone)
   */
  icone?: ReactNodeLike;
  /** Afficher le texte de l'icone */
  showTextMobile?: boolean;
  /** Afficher le divider */
  showDividerMobile?: boolean;
}

const menuLogout: MenuItem = {
  key: MenuEnum.MENU_LOGOUT,
  path: LOGOUT,
  icone: <SGAvenirNavLoginOut />,
  showTextMobile: false,
};

export function getIdTracking(menu: string): string {
  switch (menu) {
    case MenuEnum.MENU_LOGOUT:
      return "retour-aux-comptes";
    case MenuEnum.PROFIL:
      return "profil";
    case MenuEnum.MENU_DASHBOARD:
      return "mon-espace-retraite";
    case MenuEnum.OUTILS:
      return "mes-outils";
    case MenuEnum.PENSION:
      return "pension";
    case MenuEnum.SEI:
      return "simulation-fiscale";
    case MenuEnum.PATRIMOINE:
      return "patrimoine";
    case MenuEnum.EPARGNE:
      return "besoins-et-epargne";
    case MenuEnum.CONTENUS:
      return "mes-articles-et-guides";
    case MenuEnum.MENU_ARTICLES:
      return "nos-articles";
    case MenuEnum.REFORME:
      return "un-point-sur-la-reforme";
    case MenuEnum.MENU_MON_PLAN:
      return "a-l-approche-de-ma-retraite";
    case MenuEnum.SOLUTIONS:
      return "mes-solutions";
    case MenuEnum.SERVICES:
      return "sapiendo-services-publics";
    case MenuEnum.ASV:
      return "assurance-vie";
    case MenuEnum.PER:
      return "plan-epargne-retraite";
    case MenuEnum.SYNTHESE:
      return "ma-synthese-retraite";
    default:
      return "";
  }
}

export const getPathMenu = (menuItems: MenuItem[], path: string) =>
  menuItems.find(
    (menuItem: MenuItem) =>
      menuItem.path === path ||
      menuItem.sousMenus?.find(
        (sousMenuItem: MenuItem) => sousMenuItem.path === path
      )
  );

export const getPathSousMenu = (path: string, menu?: MenuItem) =>
  menu?.sousMenus?.find((sousMenuItem: MenuItem) => sousMenuItem.path === path);

export const getMenu = (menuItems: MenuItem[], key: string) =>
  menuItems.find(
    (menuItem: MenuItem) =>
      menuItem.key === key ||
      menuItem.sousMenus?.find(
        (sousMenuItem: MenuItem) => sousMenuItem.key === key
      )
  );

export const getSousMenu = (key: string, menu?: MenuItem) =>
  menu?.sousMenus?.find((sousMenuItem: MenuItem) => sousMenuItem.key === key);

const useMenu = (): MenuItem[] => {
  const { pathname } = useLocation();

  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const accountState = useSelector<State, AccountState>(
    (state) => state.account
  );

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const isParcoursRISDone = dashboardState?.dashboard?.risDate !== undefined;

  const { isParcoursPatrimoineDone } = useParcoursPatrimoine();

  const {
    disponibiliteParcoursRIS,
    disponibiliteParcoursRevenusComplementaires,
    parcoursSEIActif,
    simulateurAvantApres,
  } = useFeatures();

  const showAllMenu = useMemo(
    () =>
      accountState.account.tunnelCompleted &&
      pathname !== REFORME_DEPART_ANTICIPE &&
      isLoggedIn(accountState) &&
      pathname !== TUNNEL,
    [accountState, pathname]
  );

  const menus: MenuItem[] = useMemo<MenuItem[]>(
    () =>
      showAllMenu
        ? [
            {
              key: MenuEnum.MENU_DASHBOARD,
              path: DASHBOARD,
            },
            {
              key: MenuEnum.OUTILS,
              sousMenus: [
                {
                  key: MenuEnum.PENSION,
                  path: isParcoursRISDone
                    ? `${PARCOURS_RIS}?${QUERY_PARAM_ETAPE}=${PARAM_RESULTAT}`
                    : PARCOURS_RIS,
                  disponibiliteParcours:
                    isParcoursRISDone || disponibiliteParcoursRIS,
                },
                {
                  key: MenuEnum.PATRIMOINE,
                  path: isParcoursPatrimoineDone
                    ? `${PARCOURS_REVENUS_COMPLEMENTAIRES}?${QUERY_PARAM_ETAPE}=${PARAM_RESULTAT}`
                    : PARCOURS_REVENUS_COMPLEMENTAIRES,
                  disponibiliteParcours:
                    disponibiliteParcoursRevenusComplementaires,
                },
                {
                  key: MenuEnum.SEI,
                  path: SIMULATEUR_ECONOMIES_IMPOTS,
                  disponibiliteParcours: parcoursSEIActif,
                },
                {
                  key: MenuEnum.EPARGNE,
                  path: BESOIN_EPARGNE,
                },
              ],
            },
            {
              key: MenuEnum.CONTENUS,
              sousMenus: [
                {
                  key: MenuEnum.MENU_ARTICLES,
                  path: ARTICLES,
                },
                {
                  key: MenuEnum.REFORME,
                  path: SIMULATEUR_AVANT_APRES,
                  disponibiliteParcours:
                    simulateurAvantApres &&
                    possedeDroitsActionUtilisateur(supervisionState),
                },
                {
                  key: MenuEnum.MENU_MON_PLAN,
                  path: MON_PLAN,
                },
                {
                  key: MenuEnum.SYNTHESE,
                  path: SYNTHESE_RETRAITE,
                },
              ],
            },
            {
              key: MenuEnum.SOLUTIONS,
              sousMenus: [
                {
                  key: MenuEnum.SERVICES,
                  path: MES_SERVICES,
                },
                {
                  key: MenuEnum.ASV,
                  path: NOS_SOLUTIONS_ASV,
                },
                {
                  key: MenuEnum.PER,
                  path: NOS_SOLUTIONS_PER,
                },
              ],
            },
            {
              key: MenuEnum.PROFIL,
              path: MODIFIER_PROFIL,
              icone: <SGAvenirStrokedCompte />,
              showDividerMobile: false,
            },
            menuLogout,
          ]
        : [menuLogout],
    [
      isParcoursRISDone,
      isParcoursPatrimoineDone,
      disponibiliteParcoursRIS,
      disponibiliteParcoursRevenusComplementaires,
      parcoursSEIActif,
      simulateurAvantApres,
      showAllMenu,
      supervisionState,
    ]
  );

  return menus;
};

export { useMenu };
