import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { realizedDonationsFetched } from "./realizedDonationsSlice";
import { FETCH_REALIZED_DONATIONS, RealizedDonation } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_REALIZED_DONATIONS, safe(onDefaultError, handleGetRealizedDonations));
}

function* handleGetRealizedDonations() {
   const payload: AxiosResponse<RealizedDonation[]> = yield call(apiGet, `wealth/api/donations`);
   yield put(realizedDonationsFetched(payload.data));
}
