import { PropsWithChildren, ReactNode } from "react";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";

interface TuileCliquableProps {
  icone?: JSX.Element;
  /**
   * Pour les pictos Avenir stroked : s, m, l
   * Pour les pictos Avenir styled : s, m
   */
  iconeTaille?: string;
  titre?: string | ReactNode;
  titreTaille?: number;
  texte?: string | ReactNode;
  texteTaille?: "m" | "s";
  onClick: () => void;
  lien?: string;
  accessible?: boolean;
  disableautomargin?: boolean;
  width?: "auto" | null;
  ariaLabel?: string;
  alignchildren?: "center";
  titleLevel?: number;
}

export const TuileCliquable = ({
  icone,
  iconeTaille = "l",
  titre,
  titreTaille,
  texte,
  texteTaille = "s",
  onClick,
  lien,
  accessible = true,
  disableautomargin = false,
  width,
  ariaLabel,
  alignchildren,
  children,
  titleLevel = 3,
}: PropsWithChildren<TuileCliquableProps>) => {
  const titleVisuallevel = titreTaille ?? titleLevel + 2;

  return (
    <SGTile
      clickable={accessible ? "full" : accessible}
      disableautomargin={disableautomargin}
      width={width}
    >
      <SGBox
        alignchildren={alignchildren ? [alignchildren] : undefined}
      >
        <SGGridRow align="middle">
          {icone && (
            <SGGridCol flex="0 0 0%" textalign="center">
              <SGIcon component={icone} size={iconeTaille} />
            </SGGridCol>
          )}
          <SGGridCol flex="1 0 0%">
            {titre && (
              <SGTitle level={titleLevel} visuallevel={titleVisuallevel}>
                {titre}
              </SGTitle>
            )}
            {texte && <SGText size={texteTaille}>{texte}</SGText>}
            {children}
          </SGGridCol>
          <SGGridCol flex="0 0 0%">
            {lien ? (
              <SGLink
                href={lien}
                icon={
                  accessible ? (
                    <SGIcon component={<SGAvenirNavFleche />} />
                  ) : null
                }
                type="cta"
                onClick={onClick}
                ariaLabel={ariaLabel || `Rediriger vers ${titre}`}
              />
            ) : (
              <SGButton
                icon={
                  accessible ? (
                    <SGIcon component={<SGAvenirNavFleche />} />
                  ) : null
                }
                type="icon"
                onClick={onClick}
                ariaLabel={ariaLabel || `Rediriger vers ${titre}`}
              />
            )}
          </SGGridCol>
        </SGGridRow>
      </SGBox>
    </SGTile>
  );
};
