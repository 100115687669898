export const externalURL: { [key: string]: string } = {
  EXTERNAL_URL_AGENCES: "http://agences.sg.fr",
  EXTERNAL_URL_FACEBOOK: "https://www.facebook.com/societegenerale.france/",
  EXTERNAL_URL_TWITTER: "https://twitter.com/sg_etvous",
  EXTERNAL_URL_INSTAGRAM: "https://www.instagram.com/societegenerale/?hl:fr",
  EXTERNAL_URL_BANQUE: "https://www.privatebanking.societegenerale.com/fr/",
  EXTERNAL_URL_PROFESSIONNELS: "https://professionnels.sg.fr/",
  EXTERNAL_URL_ENTREPRISES: "https://entreprises.sg.fr/",
  EXTERNAL_URL_ASSOCIATIONS: "https://associations.sg.fr/",
  EXTERNAL_URL_ECONOMIE: "https://economiepublique.sg.fr/",
  EXTERNAL_URL_GROUPE: "https://www.societegenerale.com/fr",
  EXTERNAL_URL_INFO_RETRAITE: "https://www.info-retraite.fr",
  EXTERNAL_URL_COTISATION_CARRIERE:
    "https://www.lassuranceretraite.fr/portail-info/home/actif/salarie/droit-parcours-pro/cotisation-carriere.html",
  EXTERNAL_URL_DEMANDE_EN_LIGNE: "https://www.mademandederetraitenligne.fr/",
  EXTERNAL_URL_EXPERTS_RETRAITE:
    "https://www.agirc-arrco.fr/mes-services-particuliers/les-experts-retraite/",
  EXTERNAL_URL_AGIRC_ARRCO: "https://www.agirc-arrco.fr/",
  EXTERNAL_URL_AGIRC_ARRCO_CONTACTS: "https://www.agirc-arrco.fr/contacts/",
  EXTERNAL_URL_AGIRC_ARRCO_CONTACT:
    "https://espace-personnel.agirc-arrco.fr/#/contact",
  EXTERNAL_URL_AGIRC_ARRCO_EXPERTS:
    "https://www.agirc-arrco.fr/mes-services-particuliers/les-experts-retraite/",
  EXTERNAL_URL_CNAV_HOME:
    "https://www.lassuranceretraite.fr/portail-info/home.html",
  EXTERNAL_URL_AGENDA:
    "https://www.lassuranceretraite.fr/portail-info/home/actif/salarie/mes-demarches/organiser-son-depart-avec-mon-agenda-retraite.html",
  EXTERNAL_URL_NEWSLETTER:
    "https://s.info.lassuranceretraite.fr/5455/www/form/index.html",
  EXTERNAL_URL_PREPARE_RETRAITE:
    "https://www.lassuranceretraite.fr/portail-info/files/live/sites/pub/files/PDF/452021_Brochure_Je pr%c3%a9pare ma retraite_NAT_WEB_2020.02.pdf",
  EXTERNAL_URL_BROCHURE_MODE_EMPLOI: "https://mabrochure.info-retraite.fr/",
  EXTERNAL_URL_PODCAST:
    "https://www.lassuranceretraite.fr/portail-info/hors-menu/actualites-nationales/retraite/2021/le-temps-detre-soi-le-podcast-de.html",
  EXTERNAL_URL_INFO_RETRAITE_LOGIN: "https://www.info-retraite.fr/portail-services/login",
  EXTERNAL_URL_INFO_RETRAITE_RELATIONS:
    "https://www.info-retraite.fr/portail-services/regimes/relations",
  EXTERNAL_URL_AFFCAR_CARRIERE: "https://affcar.info-retraite.fr/carriere",
  EXTERNAL_URL_INFO_RETRAITE_ACA:
    "https://www.info-retraite.fr/portail-services/aca",
  EXTERNAL_URL_INFO_RETRAITE_DRL:
    "https://www.info-retraite.fr/portail-services/drl",
  EXTERNAL_URL_INFO_RETRAITE_DRV:
    "https://www.info-retraite.fr/portail-services/drv",
  EXTERNAL_URL_INFO_RETRAITE_IER:
    "http://www.info-retraite.fr/portail-services/ier",
  EXTERNAL_URL_TRAVAILLEURS_INDEPENDANTS:
    "https://www.secu-independants.fr/accueil",
  EXTERNAL_URL_TRAVAILLEURS_INDEPENDANTS_URSSAF:
    "https://www.urssaf.fr/portail/portail/home/connectez-vous.html",
  EXTERNAL_URL_RETRAITE_ETAT: "https://retraitesdeletat.gouv.fr/",
  EXTERNAL_URL_RETRAITE_ETAT_MILITAIRE:
    "https://retraitesdeletat.gouv.fr/accueil/simulateur?simuel-id=pmr",
  EXTERNAL_URL_RETRAITE_ETAT_CARRHOLL:
    "https://retraitesdeletat.gouv.fr/carrholl/",
  EXTERNAL_URL_RETRAITE_ETAT_RACHAT_ETUDES:
    "https://retraitesdeletat.gouv.fr/RachatEtudes/",
  EXTERNAL_URL_RETRAITE_ETAT_FAQ:
    "https://retraitesdeletat.gouv.fr/actif/je-contacte-mon-regime/faq-et-contacts",
  EXTERNAL_URL_ENSAP: "https://ensap.gouv.fr/web/accueilnonconnecte",
  EXTERNAL_URL_RETRAITE_PUBLIQUE:
    "https://sl2.cdc.retraites.fr/sl2EIhm/web/connexion",
  EXTERNAL_URL_MSA_LFP: "https://www.msa.fr/lfp",
  EXTERNAL_URL_CAVIMAC: "https://www.cavimac.fr/",
  EXTERNAL_URL_SECU_INDEPENDANTS: "https://www.secu-independants.fr/",
  EXTERNAL_URL_CNAVPL: "https://www.cnavpl.fr/",
  EXTERNAL_URL_CAVOM: "https://www.cavom.net/",
  EXTERNAL_URL_CAVOM_CONTACT: "https://www.cavom.net/page-contact/",
  EXTERNAL_URL_CARMF: "https://www.carmf.fr/",
  EXTERNAL_URL_CARMF2_RESERVIO: "https://carmf2.reservio.com/",
  EXTERNAL_URL_CARMF_ETUDE_RETRAITE_MEDECIN:
    "http://www.carmf.fr/page.php?page=doc/publications/lettrealloc/2022/02/retraite-moyenne.htm",
  EXTERNAL_URL_CARCDSF: "https://www.carcdsf.fr/",
  EXTERNAL_URL_CAISSERETRAITE: "https://caisseretraite.reservio.com/",
  EXTERNAL_URL_CAVP: "https://www.cavp.fr/",
  EXTERNAL_URL_CARPIMKO: "https://www.carpimko.com/",
  EXTERNAL_URL_CARPIMKO_CONTACTER: "https://www.carpimko.com/nous-contacter",
  EXTERNAL_URL_CARVP: "https://www.carpv.fr/",
  EXTERNAL_URL_CARVP_CONTACTER: "https://www.carpv.fr/contactez-nous",
  EXTERNAL_URL_CAVAMAC: "https://www.cavamac.fr/",
  EXTERNAL_URL_CAVAMAC_CONTACT: "https://www.cavamac.fr/contact/",
  EXTERNAL_URL_CAVEC_ACCUEIL: "https://www.cavec.fr/fr/accueil-2",
  EXTERNAL_URL_CAVEC: "https://www.cavec.fr/",
  EXTERNAL_URL_CAVEC_CONTACTER: "https://www.cavec.fr/nous-contacter/",
  EXTERNAL_URL_LACIPAV: "https://www.lacipav.fr/",
  EXTERNAL_URL_LACIPAV_CONTACTER: "https://www.lacipav.fr/nous-contacter",
  EXTERNAL_URL_CNBF: "https://www.cnbf.fr/",
  EXTERNAL_URL_CNBF_CONTACTER: "https://www.cnbf.fr/contacter-la-cnbf/",
  EXTERNAL_URL_IRCEC: "https://www.ircec.fr/",
  EXTERNAL_URL_IRCEC_CONTACTEZ: "https://www.ircec.fr/contactez-nous/",
  EXTERNAL_URL_CNIEG: "https://www.cnieg.fr/accueil.html",
  EXTERNAL_URL_CNIEG_CONTACT: "https://www.cnieg.fr/contact",
  EXTERNAL_URL_CRPCF: "https://www.lecoinretraite.fr/crpcf-retraite/",
  EXTERNAL_URL_ENIM_RETRAITE: "https://www.enim.eu/retraite",
  EXTERNAL_URL_ENIM: "https://www.enim.eu/",
  EXTERNAL_URL_ENIM_A_VOTRE_ECOUTE: "https://www.enim.eu/lenim/a-votre-ecoute",
  EXTERNAL_URL_CROPERA: "https://www.cropera.fr/",
  EXTERNAL_URL_CROPERA_CONTACTER:
    "https://www.cropera.fr/index.php/nous-contacter",
  EXTERNAL_URL_CRPRATP: "https://www.crpratp.fr/accueil",
  EXTERNAL_URL_CRPRATP_CONTACT: "https://www.crpratp.fr/contact",
  EXTERNAL_URL_CRSNCF: "https://www.cprpsncf.fr/accueil",
  EXTERNAL_URL_CRSNCF_ANTENNES: "https://www.cprpf.fr/antennes/",
  EXTERNAL_URL_IRCANTEC: "https://www.ircantec.retraites.fr/",
  EXTERNAL_URL_IRCANTEC_EMPLOYEUR:
    "https://www.ircantec.retraites.fr/employeur/parametres-utilises-ircantec",
  EXTERNAL_URL_IRCANTEC_CONTACT:
    "https://www.ircantec.retraites.fr/aide-contact",
  EXTERNAL_URL_CRPN: "https://www.crpn.fr/",
  EXTERNAL_URL_CRPN_AIDE: "https://www.crpn.fr/aide/",
  EXTERNAL_URL_CNRACL: "https://www.cnracl.retraites.fr/",
  EXTERNAL_URL_CNRACL_CONTACT:
    "https://www.cnracl.retraites.fr/aide-et-contact",
  EXTERNAL_URL_RAFP: "https://www.rafp.fr/",
  EXTERNAL_URL_RAFP_CONTACTS: "https://www.rafp.fr/formulaires-contacts",
  EXTERNAL_URL_IMPOTS_GOUV: "https://www.impots.gouv.fr/",
  EXTERNAL_URL_PARTICULIERS: "https://moncompagnonretraite.particuliers.sg.fr/",
  EXTERNAL_URL_CONTACT:
    "https://particuliers.sg.fr/aides-contact-public/votre-situation",
  EXTERNAL_URL_DEFENSEURDESDROITS: "https://formulaire.defenseurdesdroits.fr/",
  EXTERNAL_URL_DEFENSEURDESDROITS_DELEGUES:
    "https://www.defenseurdesdroits.fr/saisir/delegues",
  EXTERNAL_URL_CNIL: "https://www.cnil.fr/",
  EXTERNAL_URL_EPARGNER_PLACER:
    "https://particuliers.sg.fr/epargner-placer-son-argent",
  EXTERNAL_URL_DIAGNOSTIC_EPARGNE:
    "https://particuliers.sg.fr/epargner-placer-son-argent/diagnostic-epargne",
  EXTERNAL_URL_PER_ACACIA:
    "https://particuliers.sg.fr/epargner-placer-son-argent/nos-offres/assurance-vie/per-acacia",
  EXTERNAL_URL_REFORME_RETRAITE:
    "https://www.la-retraite-en-clair.fr/retraite-france-monde/reforme-retraites-2023/reforme-retraites-change-1er-septembre-2023",
  EXTERNAL_URL_INFO_RETRAITE_REVERSION:
    "https://services.info-retraite.fr/service/demander-ma-reversion/",
  EXTERNAL_URL_INFO_RETRAITE_ADR:
    "https://entreprendre.service-public.fr/vosdroits/F31263",
  EXTERNAL_URL_CONDITIONS_RETRAITE_ANTICIPEE:
    "https://www.service-public.fr/particuliers/vosdroits/F16337",
  EXTERNAL_URL_LASSURANCERETRAITE: "https://www.lassuranceretraite.fr",
  EXTERNAL_URL_LASSURANCERETRAITE_CONTACTS:
    "https://www.lassuranceretraite.fr/portail-info/hors-menu/footer/contacts.html",
  EXTERNAL_URL_LASSURANCERETRAITE_AUTHENTICATION:
    "https://www.lassuranceretraite.fr/portail-services-ng/authentication",
  EXTERNAL_URL_LASSURANCERETRAITE_MONTANT_RETRAITE:
    "https://www.lassuranceretraite.fr/portail-info/home/actif/travailleur-independant/calcul-retraite/retraite-complementaire.html",
  EXTERNAL_URL_MSA_LFP_ACCUEIL: "https://www.msa.fr/lfp/accueil",
  EXTERNAL_URL_MSA_LFP_CONTACT: "https://www.msa.fr/lfp/contact",
  EXTERNAL_URL_MSA_LFP_CONTACT_COORDONNEES:
    "https://www.msa.fr/lfp/contact/coordonnees-msa",
  EXTERNAL_URL_AUTOENTREPRENEUR:
    "https://www.autoentrepreneur.urssaf.fr/portail/accueil.html",
  EXTERNAL_URL_CPRN: "https://www.cprn.fr/",
  EXTERNAL_URL_CPRN_CONTACTER: "https://www.cprn.fr/contactez-nous",
  EXTERNAL_URL_ARTISTES_AUTEURS_ACCUEIL:
    "https://www.artistes-auteurs.urssaf.fr/aa/accueil",
  EXTERNAL_URL_ARTISTES_AUTEURS: "https://www.secu-artistes-auteurs.fr/",
  EXTERNAL_URL_ARTISTES_AUTEURS_CONTACTS:
    "https://www.secu-artistes-auteurs.fr/contacts",
  EXTERNAL_URL_URSSAF:
    "https://www.urssaf.fr/portail/home/espaces-dedies/artistes-auteurs-diffuseurs-comm.html",
  EXTERNAL_URL_LAMAISONDESARTISTES: "https://www.lamaisondesartistes.fr/site/",
  EXTERNAL_URL_LAMAISONDESARTISTES_CONTACT:
    "https://www.lamaisondesartistes.fr/site/contact/",
  EXTERNAL_URL_RETRAITESOLIDARITE:
    "https://maretraitepublique.caissedesdepots.fr/espace-prive/plateforme/#/bienvenue",
  EXTERNAL_URL_POLITIQUES_SOCIALES_BANQUE_DE_FRANCE:
    "https://politiques-sociales.caissedesdepots.fr/retraite-banquedefrance",
  EXTERNAL_URL_POLITIQUES_SOCIALES_FSPOEIE:
    "https://politiques-sociales.caissedesdepots.fr/FSPOEIE/",
  EXTERNAL_URL_POLITIQUES_SOCIALES_FSPOEIE_CONTACTS:
    "https://politiques-sociales.caissedesdepots.fr/FSPOEIE/contacts-fspoeie",
  EXTERNAL_URL_SECUMINES: "https://www.secumines.org/",
  EXTERNAL_URL_SECUMINES_CONTACT: "https://www.secumines.org/contact",
  EXTERNAL_URL_CRPCEN: "https://www.crpcen.fr/",
  EXTERNAL_URL_CRPCEN_CONTACTER:
    "https://www.crpcen.fr/affilies/comment-contacter-la-crpcen",
  EXTERNAL_URL_PENSION_REVERSION:
    "https://www.pension-reversion.fr/contact-port-autonome-de-strasbourg/",
  EXTERNAL_URL_PAS_CONTACT: "https://www.strasbourg.port.fr/contact/",
  EXTERNAL_URL_PLATEFORME_EMPLOYEURS:
    "https://plateforme-employeurs.caissedesdepots.fr/espace-prive/plateforme/#/public/accueil",
  EXTERNAL_URL_INFO_RETRAITE_PORTAIL_INFO:
    "https://www.info-retraite.fr/portail-info/sites/PortailInformationnel/home/demander-ma-retraite-en-ligne.html",
  EXTERNAL_URL_LASSURANCERETRAITE_AIDE:
    "https://www.lassuranceretraite.fr/portail-info/hors-menu/annexe/travailleurs-independants/aide-depart-retraite-inde.html",
  EXTERNAL_URL_INFO_RETRAITE_MICRO_ENTREPRENEUR:
    "https://entreprendre.service-public.fr/vosdroits/F23369",
  EXTERNAL_URL_VALIDER_TRIMESTRE:
    "https://www.lassuranceretraite.fr/sarn/jsp/acsarEstPopupTrimVal.jsp",
  EXTERNAL_URL_LEGIFRANCE:
    "https://www.legifrance.gouv.fr/loda/article_lc/LEGIARTI000031627660",
  EXTERNAL_URL_JURIS_CNRACL:
    "https://www.juris-cnracl.retraites.fr/motifs-conditions-de-depart/depart-au-titre-de-la-categorie-active/classement-en-categorie/emplois-revelant-de-la-fph-classes-en-categorie-active",
  EXTERNAL_URL_BOFIP:
    "https://bofip.impots.gouv.fr/bofip/359-PGP.html/identifiant%3DBOI-RSA-PENS-10-10-10-20-20130712",
  EXTERNAL_URL_LACIPAV_QUI_EST_ASSURE:
    "https://www.lacipav.fr/qui-est-assure-cipav",
  EXTERNAL_URL_LASSURANCERETRAITE_MONTANT_RETRAITE_DETAIL:
    "https://www.lassuranceretraite.fr/portail-info/hors-menu/annexe/salaries/montant-retraite/calcul-detail.html",
  EXTERNAL_URL_LASSURANCERETRAITE_CONSULT_CARR:
    "https://www.lassuranceretraite.fr/portail-services-ng/sec/dashboard/service/CONSULT_CARR",
  EXTERNAL_URL_INFO_RETRAITE_DEMANDER_EN_LIGNE:
    "https://www.info-retraite.fr/portail-info/sites/PortailInformationnel/home/demander-ma-retraite-en-ligne.html",
  EXTERNAL_URL_VIE_PUBLIQUE_AVPF:
    "https://www.vie-publique.fr/fiches/37972-assurance-vieillesse-des-parents-au-foyer-avpf",
  EXTERNAL_URL_RSE_FINANCE_DURABLE:
    "https://particuliers.sg.fr/rse/finance-durable",
  EXTERNAL_URL_FISCALITE_ASSURANCE_VIE:
    "https://particuliers.sg.fr/articles-actualites/fiscalite-assurance-vie",
  EXTERNAL_URL_PARTICULIERS_SG: "https://particuliers.sg.fr/",
};
