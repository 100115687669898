import { FC, PropsWithChildren } from "react";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";

interface Bloc5050Props {
  cover: JSX.Element;
  inverse?: boolean;
}

export const Bloc5050: FC<PropsWithChildren<Bloc5050Props>> = ({
  cover,
  inverse = false,
  children,
}) => {
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const blocCover = (
    <SGGridCol span={12} xxl={8} align="center" textalign="center">
      {cover}
    </SGGridCol>
  );

  const blocTexte = (
    <SGGridCol span={12} xxl={4} align="center">
      {children}
    </SGGridCol>
  );

  return (
    // La box permet d'éviter que le SGSpace parent casse les marges du SGGridRow.
    // Là, on est tout pile bien collé à gauche et à droite
    <SGBox>
      <SGGridRow justify="center" gutter={[32, 16]}>
        {inverse && !isPhone ? (
          <>
            {blocTexte}
            {blocCover}
          </>
        ) : (
          <>
            {blocCover}
            {blocTexte}
          </>
        )}
      </SGGridRow>
    </SGBox>
  );
};
