import {ProjectType} from "../projects/types";
import {AssetType} from "../assets/types";
import {Member} from "../members/types";

export interface RealizedDonationState {
   realizedDonations: RealizedDonation[];
   hasFetched: boolean;
}

export interface RealizedDonation {
   id: number;
   projectName: string;
   projectType: ProjectType;
   amount?: number;
   startDate: string;
   member?: Member;
   assetType: AssetType;
   alreadyRealized: boolean;
}

// actions
export const FETCH_REALIZED_DONATIONS = "FETCH_REALIZED_DONATIONS";
