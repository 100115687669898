import { FunctionComponent, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { SGAlert } from "sg-alert";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { contenusAccesContenuMonPlan } from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { AccesContenus } from "website/components/molecules/AccesContenus/AccesContenus";
import { SectionAllerPlusLoin } from "website/components/molecules/NosSolutions/SectionAllerPlusLoin";
import {
  NomPageEnum,
  ordreTuilesPage,
} from "website/components/molecules/NosSolutions/utils";
import { StickySGAgenda } from "website/components/molecules/StickySGAgenda/StickySGAgenda";
import { ImageBloc5050 } from "website/components/molecules/Templates/Bloc5050/ImageBloc5050/ImageBloc5050";

const MonPlanPage: FunctionComponent = () => {
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage("mon-plan", "accueil", "gestion-ecran");
  }, []);

  return (
    <>
      <SGLayout background="light">
        <SGContent disableautomargin>
          <SGBox padding={{ bottom: "md" }}>
            <SGSpace
              disableautomargin
              xs={{ direction: "vertical", size: "lg" }}
              md={{ direction: "vertical", size: "xl" }}
            >
              <SGTitle key="titre" level={1} textalign="center">
                <FormattedMessage id="monPlan.header" />
              </SGTitle>
              <SGAlert
                icon={<SGIcon component={<SGAvenirStatusInfo />} size="m" />}
                message={<FormattedMessage id="monPlan.disclaimer" />}
                span={12}
                type="info"
              />
              <ImageBloc5050
                key="imageBloc5050Annees"
                imgScr="images/mon-plan/quelques-annees-cover.jpg"
                imgScrMob="images/mon-plan/quelques-annees-cover-mob.jpg"
                idTitre="monPlan.blocAnnees.titre"
                niveauTitre={2}
                idTexte="monPlan.blocAnnees.description"
              />
              <ImageBloc5050
                key="imageBloc5050Retraite"
                imgScr="images/mon-plan/depart-retraite-cover.jpg"
                imgScrMob="images/mon-plan/depart-retraite-cover-mob.jpg"
                idTitre="monPlan.blocRetraite.titre"
                niveauTitre={2}
                idTexte="monPlan.blocRetraite.description"
                inverse
              />
              <SectionAllerPlusLoin
                key="sectionAllerPlusLoin"
                nom={NomPageEnum.MON_PLAN}
                ordreTuilesPage={ordreTuilesPage[NomPageEnum.MON_PLAN]}
                isSousTitre
              />
              <AccesContenus
                key="accesContenus"
                idMedias={contenusAccesContenuMonPlan}
                nbSlides={3}
              />
              <SGText>
                <FormattedMessage id="monPlan.mentions-sapiendo" />
              </SGText>
            </SGSpace>
          </SGBox>
        </SGContent>
      </SGLayout>
      <StickySGAgenda />
    </>
  );
};

export { MonPlanPage };
