import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent, SGLayout } from "sg-layout";
import { SGMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { updatePrefs } from "store/dashboard/actions";
import { User, hasFetchedDashboard } from "store/dashboard/types";
import { CustodyType, FamilyStatus, RetirementCategorySimulation } from "store/members/types";
import { callSimulateurAvantApres } from "store/simulateurAvantApres/actions";
import { setShowStep2, updateParameters } from "store/simulateurAvantApres/slice";
import { ParametresSimulateurAvantApres } from "store/simulateurAvantApres/types";
import { genreTransco, toStringDate } from "store/simulateurAvantApres/utils";
import { WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { Loader } from "website/components/atoms/Loader/Loader";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAccount } from "website/components/hooks/useAccount";
import { useFamily } from "website/components/hooks/useFamily";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import { useWorkIncomesHistory } from "website/components/hooks/useWorkIncomesHistory";
import { Faq } from "website/components/molecules/Faq/Faq";
import { MaSituationStep } from "website/components/organisms/SimulateurAvantApres/MaSituationStep/MaSituationStep";
import { ResultatsStep } from "website/components/organisms/SimulateurAvantApres/ResultatsStep/ResultatsStep";
import { DetailTabProvider } from "website/components/providers/DetailTabProvider";
import { SimulateurAvantApres } from "website/components/templates/SimulateurAvantApres/SimulateurAvantApres";
import { nextStep } from "../../store/simulateurAvantApres/slice";
import { State } from "../../store/store";

export const SimulateurAvantApresPage: FunctionComponent = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { trackPage } = useTracking();

    // Mis à vrai après le reset du parcours
    const [pageReady, setPageReady] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const currentStep = useSelector<State, number>((state) => state.simulateurAvantApres.currentStep);
    const parametres = useSelector<State, ParametresSimulateurAvantApres>((state) => state.simulateurAvantApres.parametres);
    const hasFetched = useSelector<State, boolean>((state) => state.simulateurAvantApres.hasFetched);
    const isTunnelRedirect = useSelector<State, boolean>((state) => state.simulateurAvantApres.isTunnelRedirect);
    const dateApplication = useSelector<State, string>((state) => state.simulateurAvantApres.simulateurAvantApres.dateApplication);
    const steps: FunctionComponent[] = [MaSituationStep, ResultatsStep];
    const showStep2 = useSelector<State, boolean | undefined>((state) => state.simulateurAvantApres.showStep2);

    const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);
    const { personalInformationsState } = usePersonalInformations();

    const hasFetchedFamily = useSelector<State, boolean>((state) => state.family.hasFetched);
    const family = useFamily();
    const account = useAccount();
    const dashboardState = useDashboardState();

    const faqBodyList = ["personal.data", "sapiendo"];

    useWorkIncomesHistory();

    useEffect(() => {
        // Cas du tracking via l'onboarding à cause des mentions légales affichées instantanément
        if (isTunnelRedirect) {
            trackPage("simulateur-impact-reforme", "resultats", PAGE_TYPE_FORMULAIRE, "simulation", "2", { form_field_1: "nouvel-onboarde" });
        }
    }, []);

    /**
     * Page prête à être affichée et traquée
     */
    useEffect(() => {
        if (pageReady && !isTunnelRedirect) {
            if (currentStep === 0) {
                trackPage("simulateur-impact-reforme", "ma-situation", PAGE_TYPE_FORMULAIRE, "simulation", "1");
            }
            if (currentStep === 1) {
                trackPage("simulateur-impact-reforme", "resultats", PAGE_TYPE_FORMULAIRE, "simulation", "2", { form_field_1: "onboarde" });
            }
        }
    }, [currentStep, pageReady]);

    /**
     * Paramètres récupérés d'ailleurs pour préalimentation ou appel d'API
     */
    useEffect(() => {
        // On n'écoute que les obligatoires (non saisissables)
        if (
            !pageReady &&
            workIncomesHistoryState.workIncomesHistory?.carrierStartDate &&
            hasFetchedFamily &&
            family.me.birthday &&
            personalInformationsState.hasFetched
        ) {
            // Mise à jour des parametres du simulateur avant/apres
            dispatch(
                updateParameters({
                    ...parametres,
                    nbEnfants: family.children.filter(
                        (child) =>
                            child.status === FamilyStatus.CHILD &&
                            (child.custodyType === CustodyType.FULL_CUSTODY || child.custodyType === CustodyType.FULL_CUSTODY_ME)
                    ).length,
                    dateNaissance: toStringDate(family.me.birthday, "-"),
                    statut: family.me.retirementType ? RetirementCategorySimulation[family.me.retirementType] : undefined,
                    dateDebutCarriere: toStringDate(workIncomesHistoryState.workIncomesHistory.carrierStartDate, "-", true),
                    categorieActive: personalInformationsState.personalInformations.categorieActive,
                    genre: genreTransco(account.title),
                })
            );
        }
    }, [workIncomesHistoryState, hasFetchedFamily, personalInformationsState.hasFetched]);

    /**
     * Paramètres à jour pour lancer l'appel API (ou afficher la page si certains paramètres manquent)
     */
    useEffect(() => {
        if (showStep2 !== undefined) {
            // Paramètres obligatoires
            if (!hasFetched && parametres.dateNaissance && parametres.genre && parametres.statut !== undefined && personalInformationsState.hasFetched) {
                if (showStep2 && parametres.categorieActive !== undefined && parametres.dateDebutCarriere && parametres.nbEnfants !== undefined) {
                    // Faire la simulation lorsqu'on a tous les parametres et qu'on les a mis à jour
                    dispatch(callSimulateurAvantApres(parametres));
                    setShowLoader(true);
                } else if (!showStep2) {
                    // Déblocage si pas d'appel à faire
                    setPageReady(true);
                }
            }
        }
    }, [parametres, personalInformationsState.hasFetched, showStep2, dashboardState.dashboard?.firstSimulatorBeforeAfterDate]);

    // Déblocage après récupération des résultats
    useEffect(() => {
        if (hasFetched) {
            setPageReady(true);
            setShowLoader(false);
            if (currentStep === 0) {
                dispatch(nextStep());
            }
        }
    }, [hasFetched]);

    /**
     * Gestion première simulation, pour accès direct à la seconde étape + persistance
     */
    useEffect(() => {
        // Accès direct à l'étape 2 si onboarding réforme ou seconde visite
        if (hasFetchedDashboard(dashboardState)) {
            dispatch(setShowStep2(isTunnelRedirect || dashboardState.dashboard?.firstSimulatorBeforeAfterDate !== undefined));
        }

        // Persister la premiere simulation, lorsque l'API répond
        if (hasFetched && hasFetchedDashboard(dashboardState) && dashboardState.dashboard?.firstSimulatorBeforeAfterDate === undefined) {
            const req: User = {
                firstSimulatorBeforeAfterDate: new Date(),
            };
            dispatch(updatePrefs(req));
        }
    }, [hasFetched, dashboardState.dashboard]);

    return pageReady ? (
        <SGLayout tagName="div">
            {currentStep === 1 && (
                <SGMediaQuery minwidth="sm">
                    <SGContent disableautomargin>
                        <SGBox span={9} align="center" textalign="left" margin={{ bottom: "xl", top: "xl" }}>
                            <>
                                <SGTitle caps level={1} visuallevel={2}>
                                    {intl.formatMessage({ id: "simulateurAvantApres.resultats.titre" })}
                                </SGTitle>
                                <SGText weight="400" size="m" textalign="left">
                                    {intl.formatMessage(
                                        { id: "simulateurAvantApres.resultats.sousTitre" },
                                        {
                                            date: new Date(dateApplication).toLocaleDateString("fr-fr", { year: "2-digit", month: "2-digit", day: "2-digit" }),
                                        }
                                    )}
                                </SGText>
                            </>
                        </SGBox>
                    </SGContent>
                </SGMediaQuery>
            )}
            <SGLayout tagName="div" background="light">
                <SGContent disableautomargin>
                    {showLoader ? (
                        <Loader title="loader.title" />
                    ) : (
                        <DetailTabProvider>
                            <SimulateurAvantApres currentStep={currentStep} steps={steps} />
                        </DetailTabProvider>
                    )}
                    {currentStep === 0 && (
                        <SGGridRow justify="center">
                            <SGGridCol span={12} xxl={8}>
                                <SGBox margin={{ left: "xl", right: "xl", bottom: "xl" }}>
                                    <Faq bodyFaq={faqBodyList} openDefaultKeys={[""]} isMainAccordionOpen hideMainAccordion />
                                </SGBox>
                            </SGGridCol>
                        </SGGridRow>
                    )}
                </SGContent>
            </SGLayout>
        </SGLayout>
    ) : null;
};
