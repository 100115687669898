import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TaxCredit, TaxSituation, TaxState, TaxSystem} from "./types";
import {insertElement, removeElement, updateElement} from "../utils";

const initialState: TaxState = {
   taxSituation: {
      coupleIncomeTaxList: [{
         netIncomes: 0,
         incomeTaxAmount: 0,
         numberOfParts: 0,
         marginalTaxRate: 0,
         percentageOfTheGlobalIncome: 0,
         taxDeduction: 0,
         childCareAmountDeduction: 0,
         employeeAtHomeAmountDeduction: 0,
         donationAmountDeduction: 0,
         grossTaxAmount: 0,
         socialTaxOnRentalIncomes: 0
      }], ifiTaxAmount: 0
   },
   hasFetchedTaxSituation: false,
   taxCredits: [],
   hasFetchedTaxCredits: false,
   myTaxSystem: TaxSystem.FLAT_TAX,
   partnerTaxSystem: TaxSystem.FLAT_TAX,
   hasFetchedTaxSystems: false
};

export const taxSlice = createSlice({
   name: "tax",
   initialState,
   reducers: {
      partnerTaxSystemUpdated: (currentState, action: PayloadAction<TaxSystem>) => ({
         ...currentState,
         partnerTaxSystem: action.payload
      }),
      taxCreditDeleted: (currentState, action: PayloadAction<TaxCredit>) => ({
         ...currentState,
         taxCredits: removeElement(currentState.taxCredits, String(action.payload.id))
      }),
      resetTaxState: () => initialState
   }
});

export const {
   partnerTaxSystemUpdated,
   taxCreditDeleted,
   resetTaxState
} = taxSlice.actions;

export default taxSlice.reducer;
