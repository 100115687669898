import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiGet, apiPost, apiPut } from "store/apiCaller";
import { safe } from "store/error/utils";
import { savedParametersUpdated, taxEconomyFetched, taxSimulationFetched } from "./slice";
import { EconomieImpot, FETCH_TAX_ECONOMY_SIMULATION, FETCH_TAX_ECONOMY_SIMULATION_PARAMS, ParametresSEI, TaxSimulation } from "./types";
import { onApiCallError } from "./utils";

export function* watcherSaga() {
   yield takeLatest(FETCH_TAX_ECONOMY_SIMULATION, safe(onApiCallError, handleFetchTaxEconomy));
   yield takeLatest(FETCH_TAX_ECONOMY_SIMULATION_PARAMS, safe(onApiCallError, handleFetchTaxEconomySimulationParams));
}

/**
 * Appelle le simulateur d'économie d'impôts
 * @param action Action contenant ParametresSEI
 */
function* handleFetchTaxEconomy(action: PayloadAction<ParametresSEI>) {
   // Si on a une simulationKey dans une ancienne réponse, on lance un PUT. Sinon, ce sera un POST
   const simulationKey: string = yield select((state) => state.simulateurEconomiesImpots.taxEconomy.simulationKey);
   const payload: AxiosResponse<EconomieImpot> = simulationKey
      ? yield call(apiPut, `/backpfr/api/simulation-access/simulateur-fiscal-economie-impot/${simulationKey}`, action.payload)
      : yield call(apiPost, `/backpfr/api/simulation-access/simulateur-fiscal-economie-impot`, action.payload);
   yield put(savedParametersUpdated(action.payload));
   yield put(taxEconomyFetched(payload.data));
}

/**
 * Récupère les paramètres de la dernière simulation
 */
function* handleFetchTaxEconomySimulationParams() {
   const payload: AxiosResponse<TaxSimulation> = yield call(apiGet, `/backpfr/api/simulation-access/simulateur-fiscal-economie-impot`);
   yield put(taxSimulationFetched(payload.data));
}
