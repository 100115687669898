import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SGAvenirStyledFamille } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { NOS_SOLUTIONS_ASV } from "website/utils/privateRoutes";
import { TuileCliquable } from "../TuileCliquable/TuileCliquable";

export const TuileASV: FC = () => {
  const { trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <TuileCliquable
      icone={<SGAvenirStyledFamille />}
      iconeTaille="s"
      titre={<FormattedMessage id="sectionAllerPlusLoin.tuile.asv.titre" />}
      texte={<FormattedMessage id="sectionAllerPlusLoin.tuile.asv.texte" />}
      onClick={() => {
        trackClick("clic-sur-card-assurance-vie");
      }}
      alignchildren={isPhone ? undefined : "center"}
      lien={`#${NOS_SOLUTIONS_ASV}`}
    />
  );
};
