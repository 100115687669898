import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RetirementCategory } from "../members/types";
import { storeDeepEquals } from "../utils";
import { IncomeLine, Project, ProjectState, RetirementProjectValues, WorkIncomeEvolutionLevel } from "./types";

const initialState: ProjectState = {
   projects: new Array<Project>(),
   hasFetchedProjects: false,
   hasFetchedFeasibilitiesError: false,
   hasFetchedFeasibilities: false,
   projectFeasibilities: [],
   additionalIncomeObjective: 0,
   changeLifeFutureExpenses: {
      common: 0,
      projects: 0,
      other: 0,
      total: 0
   },
   changeLifeMyFutureIncome: 0,
   changeLifePartnerFutureIncome: 0,
   givenDonationFees: undefined,
   retirementProject: {
      myRetirement: {
         memberId: 0,
         memberName: "",
         birthdate: new Date(),
         currentMonthlyIncome: 0,
         type: RetirementCategory.SALARIE_PRIVE_CADRE, // TODO : Verif saisie
         salaryEvolutionLevel: WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES,
         retirementYearToRetirementIncomeMap: {},
         retirementYearToIncomeBeforeRetirementMap: {},
         retirementIncomes: new Array<IncomeLine>(),
         memberRetirementDate: new Date()
      },
      partnerRetirement: {
         memberId: 0,
         memberName: "",
         birthdate: new Date(),
         currentMonthlyIncome: 0,
         type: RetirementCategory.SALARIE_PRIVE_CADRE, // TODO : Verif saisie
         salaryEvolutionLevel: WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES,
         retirementYearToRetirementIncomeMap: {},
         retirementYearToIncomeBeforeRetirementMap: {},
         retirementIncomes: new Array<IncomeLine>(),
         memberRetirementDate: new Date()
      },
      expenses: {
         common: 0,
         projects: 0,
         other: 0,
         total: 0
      },
      objective: 0,
      projectId: 0
   },
   hasFetchedMyRetirementSimulation: false,
   hasFetchedPartnerRetirementSimulation: false,
   isRetirementProjectAutomaticallyUpdated: false
};

export const projectsSlice = createSlice({
   name: "projects",
   initialState,
   reducers: {
      retirementProjectUpdated: (currentState, action: PayloadAction<RetirementProjectValues>) => ({
         ...currentState,
         retirementProject: action.payload,
         hasFetchedMyRetirementSimulation: action.payload.myRetirement !== undefined,
         hasFetchedPartnerRetirementSimulation: action.payload.partnerRetirement !== undefined
      }),
      allProjectsDeleted: (currentState) => ({
         ...currentState, projects: [], numberProjects: 0
      }),
      projectsFetched: (currentState, action: PayloadAction<Project[]>) => ({
         ...currentState,
         projects: storeDeepEquals(currentState.projects, action.payload) ? currentState.projects : action.payload,
         hasFetchedProjects: true
      }),
      feasibilityError: (currentState) => ({
         ...currentState, hasFetchedFeasibilitiesError: true
      }),
   }
});

export const {
   retirementProjectUpdated,
   allProjectsDeleted,
   projectsFetched,
   feasibilityError,
} = projectsSlice.actions;

export default projectsSlice.reducer;
