import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { SGContent, SGLayout } from "sg-layout";
import { SGBox } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { SGTitle } from "sg-typo";
import { updatePrefs } from "store/dashboard/actions";
import { User, hasFetchedDashboard } from "store/dashboard/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { servicesSapiendo } from "website/components/hooks/servicesSapiendoList";
import { PAGE_TYPE_GESTION } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { ServiceSapiendoModal } from "website/components/molecules/ServiceSapiendoModal/ServiceSapiendoModal";
import { ExpertRetraite, ServiceCardInfo, ServiceFormule } from "./ExpertRetraite/ExpertRetraite";
import { ExpertRetraiteInfos } from "./ExpertRetraite/ExpertRetraiteInfos/ExpertRetraiteInfos";
import "./MesServices.scss";
import { MesServicesPublics } from "./MesServicesPublics/MesServicesPublics";
import { ServiceSapiendoModel } from "./ServiceModel";

const MesServices: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();
    const { search } = useLocation();
    const urlSearchParams = new URLSearchParams(search);

    const dashboardState = useDashboardState();
    const [currentKey, setCurrentKey] = useState(urlSearchParams.get("tab") || "sapiendo");
    const [selectedService, setSelectedService] = useState<ServiceSapiendoModel>();
    const [showModalInfo, setShowModalInfo] = useState<boolean>(false);
    const [serviceId, setServiceId] = useState<string>();
    const [goPremiumService, setGoPremiumService] = useState(false);
    const { trackPopin, trackTab, trackPage } = useTracking();

    const services: ServiceCardInfo[] = [
        { type: "entretien", fileName: servicesSapiendo[0], items: ["duree", "reponses", "etranger"], trackKey: "entretien-retraite" },
        { type: "bilan", fileName: servicesSapiendo[1], items: ["point", "releve", "optimiser"], formule: ServiceFormule.ESSENTIELLE, trackKey: "bilan-retraite-formule-essentielle" },
        { type: "bilanPremium", fileName: servicesSapiendo[2], items: ["point", "releve", "correction", "optimiser"], formule: ServiceFormule.PREMIUM, trackKey: "bilan-retraite-formule-premium" },
        { type: "aide", fileName: servicesSapiendo[3], items: ["conseil", "dossiers", "hotline", "information"], formule: ServiceFormule.ESSENTIELLE, trackKey: "aide-au-depart-a-la-retraite-formule-essentielle" },
        {
            type: "aidePremium",
            fileName: servicesSapiendo[4],
            items: ["demandes", "démarches", "interactions", "consultant"],
            formule: ServiceFormule.PREMIUM,
            trackKey: "aide-au-depart-a-la-retraite-formule-premium"
        },
    ];

    const popinsTracks = [
        {
            type: "bilan-retraite-essentielle",
            trackKey: "bilan-retraite-formule-essentielle::clic-sur-voir-l-entretien-bilan-retraite-sur-mesure"
        },
        {
            type: "aide-retraite-essentielle",
            trackKey: "aide-au-depart-a-la-retraite-formule-essentielle::clic-sur-voir-l-aide-au-depart-a-la-retraite-formule-premium"
        }
    ]

    const TABS = [
        {
            key: "sapiendo",
            component: <ExpertRetraite services={services} setServiceId={setServiceId} setShowModalInfo={setShowModalInfo} />,
            trackKey: "sapiendo-l-expert-retraite",
        },
        {
            key: "services",
            component: <MesServicesPublics />,
            trackKey: "les-principaux-services-publics",
        },
    ];

    const handleOpenModal = () => {
        setShowModalInfo(false);
        trackPopin(true, 'mes-services', `sapiendo-l-expert-retraite::popin-${popinsTracks.find((track) => track.type === serviceId)?.trackKey}`);
        const idService = servicesSapiendo.find((ser) => ser === selectedService?.promotionalInsert?.link);
        if (idService) {
            setGoPremiumService(true);
            setServiceId(idService);
        }
    };

    const changeTab = (activeKey: React.SetStateAction<string>) => {
        trackTab(`clic-sur-onglet-${TABS.find((tab) => tab.key === activeKey)?.trackKey}`);
        // Si on était sur les services publics, on arrive sur Sapiendo
        saveEvent(currentKey === TABS[0].key ? trackingEvent.SERVICES_SAPIENDO : trackingEvent.SERVICES_PUBLICS);
        setCurrentKey(activeKey.toString())
    }

    useEffect(() => {
        trackPage("mes-services", "accueil", PAGE_TYPE_GESTION);
        saveEvent(trackingEvent.SERVICES_PUBLICS);
    }, []);

    useEffect(() => {
        if (goPremiumService) {
            setShowModalInfo(true);
            setGoPremiumService(false);
        }
    }, [selectedService]);

    useEffect(() => {
        // save first my services date
        if (hasFetchedDashboard(dashboardState) && dashboardState.dashboard.firstMyServicesDate === undefined) {
            const req: User = {
                firstMyServicesDate: new Date(),
            };
            dispatch(updatePrefs(req));
        }
    }, [dashboardState]);

    useEffect(() => {
        if (serviceId !== undefined) {
            fetch(`services/sapiendo/${serviceId}.json`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then((response) => response.json())
                .then((myJson) => {
                    setSelectedService(myJson);
                });
        }
    }, [serviceId]);

    return (
        <>
            <SGLayout tagName="div">
                <SGContent disableautomargin>
                    <SGBox margin={{top: "md"}}>
                        <SGTitle caps level={1} visuallevel={2}>
                            {intl.formatMessage({ id: "mesServices.header" })}
                        </SGTitle>
                    </SGBox>
                </SGContent>
            </SGLayout>
            <SGLayout className="mes-services mes-services__container">
                <SGContent>
                    <SGBox margin={{ bottom: "sm" }}>
                        <SGTabs activeKey={currentKey} onChange={(activeKey: React.SetStateAction<string>) => changeTab(activeKey)}>
                            {TABS.map((item) => (
                                <SGTabPane
                                    ariaId={item.key}
                                    tab={
                                        <SGBox align="center" textalign="left" disableautomargin>
                                            {intl.formatMessage({ id: `mesServices.tabs.${item.key}` })}
                                        </SGBox>
                                    }
                                    key={item.key}
                                >
                                    {item.component}
                                </SGTabPane>
                            ))}
                        </SGTabs>
                    </SGBox>
                </SGContent>
                {currentKey === "sapiendo" && <ExpertRetraiteInfos services={services} setServiceId={setServiceId} setShowModalInfo={setShowModalInfo} />}
                {selectedService && showModalInfo && (
                    <ServiceSapiendoModal
                        selectedService={selectedService}
                        showModalInfo={showModalInfo}
                        setShowModalInfo={setShowModalInfo}
                        handleOpenModal={handleOpenModal}
                        trackKey={services.find((service) => service.fileName === serviceId)?.trackKey}
                    />
                )}
            </SGLayout>
        </>
    );
};
export { MesServices };
