export interface ElxColor {
   label: string;
   hexCode: string;
}

//  Salaires
export const FOREST_GREEN: ElxColor = {label: "forest-green", hexCode: "#57a773"};
// Pensions
export const TEA_GREEN: ElxColor = {label: "tea-green", hexCode: "#d0ecc1"};
// Pensions Alimentaires
export const CHESTNUT: ElxColor = {label: "chestnut", hexCode: "#8c3f2c"};
// Ces couleurs ne doivent être changées que si elles changent sur l'EIP Société Générale
// Comptes Courants
export const SG_BLUE: ElxColor = {label: "sg-blue", hexCode: "#046aa7"};
// Comptes bancaires
export const SG_ORANGE: ElxColor = {label: "sg-orange", hexCode: "#b56e2b"};
// Immobilier
export const SG_PINK: ElxColor = {label: "sg-pink", hexCode: "#f05b6f"};
// Prêts
export const SG_PURPLE: ElxColor = {label: "sg-purple", hexCode: "#7e00ae"};
// Autres
export const SG_BLACK: ElxColor = {label: "sg-black", hexCode: "#303030"};
