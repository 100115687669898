import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import {
  SGAvenirStrokedTelechargement,
  SGAvenirStyledEpargne,
  SGAvenirStyledRetraite,
  SGAvenirStyledSuiviVirement,
} from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGBox, SGSpace } from "sg-space";
import { SGSpinner } from "sg-spinner";
import { telechargerSyntheseRetraite } from "store/pdf/actions";
import { resetErreurPdf } from "store/pdf/pdfSlice";
import { PdfState } from "store/pdf/types";
import { State } from "store/store";
import { contenusAccesContenuSyntheseRetraite } from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { AccesContenus } from "website/components/molecules/AccesContenus/AccesContenus";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { SectionAllerPlusLoin } from "website/components/molecules/NosSolutions/SectionAllerPlusLoin";
import {
  NomPageEnum,
  ordreTuilesPage,
} from "website/components/molecules/NosSolutions/utils";
import { StickySGAgenda } from "website/components/molecules/StickySGAgenda/StickySGAgenda";
import { ImageBloc5050 } from "website/components/molecules/Templates/Bloc5050/ImageBloc5050/ImageBloc5050";
import { BlocPicto } from "website/components/molecules/Templates/BlocPicto";

const SyntheseRetraitePage: FunctionComponent = () => {
  const dispatch = useDispatch();

  const { trackPage, trackDownload } = useTracking();

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const pdfState = useSelector<State, PdfState>((state) => state.pdf);
  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );

  const handleOnClick = () => {
    trackDownload("telechargement-synthese-retraite");
    dispatch(telechargerSyntheseRetraite());
  };

  useEffect(() => {
    trackPage("ma-synthese-retraite", "accueil", "gestion-ecran");
  }, []);

  useEffect(() => {
    if (pdfState.erreur) {
      setShowErrorModal(true);
      dispatch(resetErreurPdf());
    }
  }, [pdfState.erreur]);

  return (
    <>
      <SGLayout background="light">
        <SGContent disableautomargin>
          <SGBox padding={{ bottom: "md" }}>
            <SGSpace
              disableautomargin
              xs={{ direction: "vertical", size: "lg" }}
              md={{ direction: "vertical", size: "xl" }}
            >
              <ImageBloc5050
                key="imageBloc5050"
                imgScr="images/syntheseRetraite/cover.png"
                imgScrMob="images/syntheseRetraite/cover-mob.png"
                idTitre="syntheseRetraite.titre"
                idTexte="syntheseRetraite.description"
                bouton={
                  <SGButton
                    type="tertiary"
                    iconposition="left"
                    icon={
                      <SGIcon
                        currentcolor
                        component={
                          pdfState.enChargement ? (
                            <SGSpinner size="md" />
                          ) : (
                            <SGAvenirStrokedTelechargement />
                          )
                        }
                      />
                    }
                    onClick={handleOnClick}
                    disabled={pdfState.enChargement || estSupervisionActive}
                  >
                    <FormattedMessage id="syntheseRetraite.bouton" />
                  </SGButton>
                }
              />
              <BlocPicto
                key="sectionPointsCles"
                nom={NomPageEnum.SYNTHESE_RETRAITE}
                spanBreakpoint={{ md: 4, xxl: 3 }}
                icones={[
                  {
                    key: "SGAvenirStyledSuiviVirement",
                    icone: <SGAvenirStyledSuiviVirement />,
                  },
                  {
                    key: "SGAvenirStyledRetraite",
                    icone: <SGAvenirStyledRetraite />,
                  },
                  {
                    key: "SGAvenirStyledEpargne",
                    icone: <SGAvenirStyledEpargne />,
                  },
                ]}
              />
              <SectionAllerPlusLoin
                key="sectionAllerPlusLoin"
                nom={NomPageEnum.SYNTHESE_RETRAITE}
                ordreTuilesPage={ordreTuilesPage[NomPageEnum.SYNTHESE_RETRAITE]}
                isSousTitre
              />

              <AccesContenus
                key="accesContenus"
                idMedias={contenusAccesContenuSyntheseRetraite}
                nbSlides={3}
              />
            </SGSpace>
          </SGBox>

          {showErrorModal && (
            <ErrorModal
              visible={showErrorModal}
              setVisible={setShowErrorModal}
              description="modal.error.message.indisponible"
            />
          )}
        </SGContent>
      </SGLayout>
      <StickySGAgenda />
    </>
  );
};

export { SyntheseRetraitePage };
