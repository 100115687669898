import { FunctionComponent } from "react";
import {
  SGAvenirStyledArbre,
  SGAvenirStyledBouclierOk,
  SGAvenirStyledEpargne,
  SGAvenirStyledEpargneBanquePrive,
} from "sg-icon-pack-base";
import {
  NomPageEnum,
  TabNosSolutionsEnum,
} from "website/components/molecules/NosSolutions/utils";
import { NosSolutionsTemplate } from "../components/templates/NosSolutions/NosSolutionsTemplate";

const NosSolutionsAsvPage: FunctionComponent = () => (
    <NosSolutionsTemplate
      nom={NomPageEnum.ASV}
      icones={[
        {
          icone: <SGAvenirStyledEpargneBanquePrive />,
          key: "epargneBanquePrive",
        },
        { icone: <SGAvenirStyledEpargne />, key: "epargne" },
        { icone: <SGAvenirStyledBouclierOk />, key: "bouclierok" },
        { icone: <SGAvenirStyledArbre />, key: "arbre" },
      ]}
      tabs={[
        TabNosSolutionsEnum.SOUSCRIPTION,
        TabNosSolutionsEnum.ALIMENTATION,
        TabNosSolutionsEnum.MODE_DE_GESTION,
        TabNosSolutionsEnum.RECUPERATION,
      ]}
      spanSectionPointsCles={6}
      trackingName="assurance-vie"
    />
  );

export { NosSolutionsAsvPage };
