import { FunctionComponent } from "react";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { externalURL } from "website/utils/externalUrl";
import { CarteCliquable } from "../CarteCliquable/CarteCliquable";

const CartePortailInfoRetraite: FunctionComponent = () => {
  const { trackClick } = useTracking();
  const openLink = useOpenLink();

  const handleOnClick = () => {
    trackClick("card-portail-info-retraite");
    openLink(externalURL.EXTERNAL_URL_INFO_RETRAITE_LOGIN);
  };

  return (
    <CarteCliquable
      src="images/mon-plan/portail-info-retraite.jpg"
      alt="Portail Info retraite"
      idTitre="sectionAllerPlusLoin.tuile.portailInfoRetraite.titre"
      idTexte="sectionAllerPlusLoin.tuile.portailInfoRetraite.texte"
      onClick={handleOnClick}
    />
  );
};

export { CartePortailInfoRetraite };
