import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiDelete, apiGet } from "../apiCaller";
import { getAssetsWithoutLoans } from "../assets/actions";
import { onDefaultError, safe } from "../error/utils";
import { getExpensesAction } from "../expenses/actions";
import { incomeDeleted, incomesFetched } from "./incomesSlice";
import {
   DELETE_INCOME,
   FETCH_INCOMES,
   Income
} from "./types";

export function* watcherSaga() {
   // On laisse takeEvery pour la création et la maj d'incomes dans le cas où l'on insère le salaire des deux membres du couple en une fois
   yield takeLeading(FETCH_INCOMES, safe(onDefaultError, handleGetIncomes));
   yield takeLeading(DELETE_INCOME, safe(onDefaultError, handleDeleteIncome));
}

function* handleGetIncomes() {
   const payload: AxiosResponse<Income[]> = yield call(apiGet, `wealth/api/incomes`);
   yield put(incomesFetched(payload.data));
}

function* handleDeleteIncome(action: PayloadAction<string>) {
   const incomeId: string = action.payload;
   yield call(apiDelete, `wealth/api/projects/${incomeId}`);
   yield put(incomeDeleted(incomeId));
   yield put(getExpensesAction());
   yield put(getAssetsWithoutLoans());
}
