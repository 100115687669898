import { Member } from "../members/types";
import {
  ElxColor,
  SG_BLUE,
  SG_BLACK,
  SG_ORANGE,
  SG_PINK,
  SG_PURPLE,
} from "../../website/utils/graphs/colors";

// state
export interface AssetState {
  assets: AssetWithValuation[];
  assetsImported: AssetWithValuation[]; // Contient les Assets existant sur le site de la Société Générale
  hasFetched: boolean;
  hasFetchedAssetsImported: boolean;
  showSGImportAssetModal: boolean;
}

// interfaces
export interface Asset {
  id: number;
  externalId: string | null;
  clientId: number;
  assetName: string;
  assetType: AssetType;
  acquisitionDate: Date;
  loans: Array<number>;
  assetMemberLinkSet: Array<Partial<AssetMemberLink>>;
  beingBoughtBack: boolean;
  externalProductType: string;
  percentageUsedForRetirement: number;
}

export interface AssetValuation {
  id: number | null;
  source: string;
  valuationDate: string;
  totalValuation: number;
  assetId: number;
  safeAssetPercentageKnown: boolean;
  safeAssetPercentage: number;
}

export interface AssetWithValuation extends Asset {
  assetValuation: Partial<AssetValuation>;
}

export interface RealEstateAsset extends AssetWithValuation {
  taxSystemSubType: RealEstateAssetTaxSystemSubType;
  taxSystemType: RealEstateAssetTaxSystemType;
  yearlyRentAmount: number;
  yearlyCostAmount: number;
  associatedSCI?: SCIAsset;
}

export interface SCIAsset extends AssetWithValuation {
  cashValuation?: number;
  isTaxTransparent: boolean;
  associatedRealEstateAssets: RealEstateAsset[];
}

export type AssetCategoryKey =
  | "BANK_ACCOUNT"
  | "SAVINGS_ACCOUNT"
  | "INVESTMENT_ACCOUNT"
  | "TERM_ACCOUNT"
  | "LOAN"
  | "OTHER"
  | "REAL_ESTATE";

export interface AssetCategory {
  priority: number;
  intlKey: AssetCategoryKey;
  color: ElxColor;
}

export const BANK_ACCOUNT: AssetCategory = {
  priority: 1,
  intlKey: "BANK_ACCOUNT",
  color: SG_BLUE,
};
export const SAVINGS_ACCOUNT: AssetCategory = {
  priority: 2,
  intlKey: "SAVINGS_ACCOUNT",
  color: SG_ORANGE,
};
export const TERM_ACCOUNT: AssetCategory = {
  priority: 3,
  intlKey: "TERM_ACCOUNT",
  color: SG_ORANGE,
};
export const INVESTMENT_ACCOUNT: AssetCategory = {
  priority: 4,
  intlKey: "INVESTMENT_ACCOUNT",
  color: SG_ORANGE,
};
export const REAL_ESTATE: AssetCategory = {
  priority: 5,
  intlKey: "REAL_ESTATE",
  color: SG_PINK,
};
export const LOAN: AssetCategory = {
  priority: 6,
  intlKey: "LOAN",
  color: SG_PURPLE,
};
export const OTHER: AssetCategory = {
  priority: 7,
  intlKey: "OTHER",
  color: SG_BLACK,
};

export const DISPLAYED_ASSET_CATEGORIES = [
  BANK_ACCOUNT,
  SAVINGS_ACCOUNT,
  TERM_ACCOUNT,
  INVESTMENT_ACCOUNT,
  REAL_ESTATE,
  OTHER,
].sort((category1, category2) => category1.priority - category2.priority);

export interface AssetMemberLink {
  familyMember?: Member;
  possessionType: PossessionType;
  percentage: number;
}

// enums
export const AssetType = {
  LIFE_INSURANCE: "LIFE_INSURANCE",
  LIVRET_A: "LIVRET_A",
  LDDS: "LDDS",
  COMPTE_A_TERME: "COMPTE_A_TERME",
  CSL: "CSL",
  PER: "PER",
  PEA: "PEA",
  CTO: "CTO",
  PEL: "PEL",
  PEE: "PEE",
  REAL_ESTATE_PRIMARY: "REAL_ESTATE_PRIMARY",
  REAL_ESTATE_SECONDARY: "REAL_ESTATE_SECONDARY",
  REAL_ESTATE_RENT: "REAL_ESTATE_RENT",
  CASH_ACCOUNT: "CASH_ACCOUNT",
  SCI: "SCI",
  OTHER: "OTHER",
};

export const actifsNonProjetables: string[] = [
  AssetType.LIVRET_A,
  AssetType.LDDS,
  AssetType.CSL,
  AssetType.REAL_ESTATE_PRIMARY,
  AssetType.REAL_ESTATE_SECONDARY,
  AssetType.OTHER,
  AssetType.CASH_ACCOUNT,
];

export type AssetType = (typeof AssetType)[keyof typeof AssetType];

export const REAL_ESTATE_ASSETS: Array<AssetType> = [
  AssetType.REAL_ESTATE_PRIMARY,
  AssetType.REAL_ESTATE_SECONDARY,
  AssetType.REAL_ESTATE_RENT,
];

// #ASSU - Désactivation de la date pour tous les assets
export const MANDATORY_ACQUISITION_DATE_ASSETS: Array<AssetType> = [];

export const RealEstateAssetTaxSystemType = {
  REAL_COSTS_REAL_ESTATE_INCOME: "REAL_COSTS_REAL_ESTATE_INCOME",
  MICRO_FONCIER_REAL_ESTATE_INCOME: "MICRO_FONCIER_REAL_ESTATE_INCOME",
  MICRO_BIC_REAL_ESTATE_INCOME: "MICRO_BIC_REAL_ESTATE_INCOME",
  DEFISCALISATION_REAL_ESTATE_INCOME: "DEFISCALISATION_REAL_ESTATE_INCOME",
};

export type RealEstateAssetTaxSystemType =
  (typeof RealEstateAssetTaxSystemType)[keyof typeof RealEstateAssetTaxSystemType];

export const RealEstateAssetTaxSystemSubType = {
  SCELLIER_REAL_ESTATE_INCOME: "SCELLIER_REAL_ESTATE_INCOME",
  DUFLOT_9_ANS_REAL_ESTATE_INCOME: "DUFLOT_9_ANS_REAL_ESTATE_INCOME",
  PINEL_6_ANS_REAL_ESTATE_INCOME: "PINEL_6_ANS_REAL_ESTATE_INCOME",
  PINEL_9_ANS_REAL_ESTATE_INCOME: "PINEL_9_ANS_REAL_ESTATE_INCOME",
  PINEL_12_ANS_REAL_ESTATE_INCOME: "PINEL_12_ANS_REAL_ESTATE_INCOME",
  CENSI_BOUVARD_REAL_ESTATE_INCOME: "CENSI_BOUVARD_REAL_ESTATE_INCOME",
};

export type RealEstateAssetTaxSystemSubType =
  (typeof RealEstateAssetTaxSystemSubType)[keyof typeof RealEstateAssetTaxSystemSubType];

export const PossessionType = {
  PLEINE_PROPRIETE: "PLEINE_PROPRIETE",
  NUE_PROPRIETE: "NUE_PROPRIETE",
  QUASI_USUFRUIT: "QUASI_USUFRUIT",
  USUFRUIT: "USUFRUIT",
  SCI: "SCI",
};

export type PossessionType =
  (typeof PossessionType)[keyof typeof PossessionType];

// actions
export const FETCH_ASSETS_WITHOUT_LOANS = "FETCH_ASSETS_WITHOUT_LOANS";
export const CREATE_ASSET = "CREATE_ASSET";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const UPDATE_ASSETS_FOR_RETIREMENT = "UPDATE_ASSETS_FOR_RETIREMENT";
export const ASSETS_FOR_RETIREMENT_UPDATED = "ASSETS_FOR_RETIREMENT_UPDATED";
export const DELETE_ASSET = "DELETE_ASSET";
export const CREATE_REAL_ESTATE_ASSET = "CREATE_REAL_ESTATE_ASSET";
export const UPDATE_REAL_ESTATE_ASSET = "UPDATE_REAL_ESTATE_ASSET";
export const CREATE_SCI_ASSET = "CREATE_SCI_ASSET";
export const UPDATE_SCI_ASSET = "UPDATE_SCI_ASSET";
