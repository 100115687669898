import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import AccountReducer from "./account/accountSlice";
import { watcherSaga as watchAccount } from "./account/sagas";
import { AccountState } from "./account/types";
import { AppointmentReducer } from "./appointment/reducers";
import { watcherSaga as watchAppointment } from "./appointment/sagas";
import { AppointmentState } from "./appointment/types";
import { AssetIncomesReducer } from "./assetIncomes/reducers";
import { watcherSaga as watchAssetIncomes } from "./assetIncomes/sagas";
import { AssetIncomesState } from "./assetIncomes/types";
import { AssetPercentagesReducer } from "./assetPercentages/reducers";
import { AssetPercentagesState } from "./assetPercentages/types";
import AssetsReducer from "./assets/assetsSlice";
import { watcherSaga as watchAssets } from "./assets/sagas";
import { AssetState } from "./assets/types";
import { DashboardReducer } from "./dashboard/reducers";
import { watcherSaga as watchDashboard } from "./dashboard/sagas";
import { DashboardState } from "./dashboard/types";
import ErrorReducer from "./error/errorsSlice";
import { watcherSaga as watchErrors } from "./error/sagas";
import { ErrorState } from "./error/types";
import ExpensesReducer from "./expenses/expensesSlice";
import { watcherSaga as watchExpenses } from "./expenses/sagas";
import { ExpenseState } from "./expenses/types";
import { FeaturesReducer } from "./features/reducers";
import { watcherSaga as watchFeatures } from "./features/sagas";
import { FeaturesState } from "./features/types";
import ImportReducer from "./import/importSlice";
import { watcherSaga as watchImport } from "./import/sagas";
import { ImportState } from "./import/types";
import { PrivateDataReducer } from "./importPrivateData/reducer";
import { watcherSaga as watchPrivateData } from "./importPrivateData/sagas";
import { PrivateDataState } from "./importPrivateData/types";
import IncomesReducer from "./incomes/incomesSlice";
import { watcherSaga as watchIncomes } from "./incomes/sagas";
import { IncomeState } from "./incomes/types";
import LoansReducer from "./loans/loansSlice";
import { watcherSaga as watchLoans } from "./loans/sagas";
import { LoanState } from "./loans/types";
import LoginReducer from "./login/loginSlice";
import { watcherSaga as watchLogin } from "./login/sagas";
import { LoginState, USER_LOGOUT } from "./login/types";
import FamilyReducer from "./members/membersSlice";
import { watcherSaga as watchMembers } from "./members/sagas";
import { FamilyState } from "./members/types";
import { ProfileReducer } from "./modifierProfil/reducers";
import { watcherSaga as watchProfile } from "./modifierProfil/sagas";
import { ProfileState } from "./modifierProfil/types";
import NewProjectsReducer from "./newProjects/newProjectsSlice";
import { watcherSaga as watchNewProjects } from "./newProjects/sagas";
import { NewProjectState } from "./newProjects/types";
import { ParcoursRISReducer } from "./parcoursRIS/reducer";
import { ParcoursRISState } from "./parcoursRIS/types";
import { ParcoursRevenusComplementairesReducer } from "./parcoursRevenusComplementaires/reducer";
import { ParcoursRevenusComplementairesState } from "./parcoursRevenusComplementaires/types";
import { ParcoursRisInformationsComplementairesReducer } from "./parcoursRisInformationsComplementaires/reducers";
import watchParcoursRisInformationsComplementaires from "./parcoursRisInformationsComplementaires/sagas";
import { ParcoursRisInformationsComplementairesState } from "./parcoursRisInformationsComplementaires/types";
import { ParcoursRisProjectionReducer } from "./parcoursRisProjection/reducers";
import watchParcoursRisProjection from "./parcoursRisProjection/sagas";
import { ParcoursRisProjectionState } from "./parcoursRisProjection/types";
import { ParcoursRisUploadReducer } from "./parcoursRisUpload/reducers";
import watchParcoursRisUpload from "./parcoursRisUpload/sagas";
import { ParcoursRisUploadState } from "./parcoursRisUpload/types";
import PdfReducer from "./pdf/pdfSlice";
import { watcherSaga as watchPdf } from "./pdf/sagas";
import { PdfState } from "./pdf/types";
import { PersonalInformationsReducer } from "./personalInformations/reducer";
import { watcherSaga as watchPersonalInformations } from "./personalInformations/sagas";
import { PersonalInformationsState } from "./personalInformations/types";
import { watcherSaga as watchPersonnalisationDashboard } from "./personnalisation/dashboard/sagas";
import PersonnalisationDashboardSlice from "./personnalisation/dashboard/slice";
import { PersonnalisationDashboardState } from "./personnalisation/dashboard/types";
import { watcherSaga as watchPersonnalisationQuestions } from "./personnalisation/questions/sagas";
import PersonnalisationQuestionsSlice from "./personnalisation/questions/slice";
import { PersonnalisationQuestionsState } from "./personnalisation/questions/types";
import ProjectsReducer from "./projects/projectsSlice";
import { watcherSaga as watchProjects } from "./projects/sagas";
import { ProjectState } from "./projects/types";
import PromotionalOfferReducer from "./promotionalOffers/promotionalOfferSlice";
import { watcherSaga as watchPromotionalOffer } from "./promotionalOffers/sagas";
import { PromotionalOfferState } from "./promotionalOffers/types";
import RealizedDonationsReducer from "./realizedDonations/realizedDonationsSlice";
import { watcherSaga as watchRealizedDonations } from "./realizedDonations/sagas";
import { RealizedDonationState } from "./realizedDonations/types";
import { EffortReducer, SavingPathReducer } from "./savingPath/reducers";
import { watcherSaga as watchSavingPath } from "./savingPath/sagas";
import { EffortState, SavingPathState } from "./savingPath/types";
import { watcherSaga as watchSignUp } from "./signup/sagas";
import SignUpReducer from "./signup/signupSlice";
import { SignUpState } from "./signup/types";
import { watcherSaga as watchSimulateurAvantApres } from "./simulateurAvantApres/sagas";
import SimulateurAvantApresReducer from "./simulateurAvantApres/slice";
import { SimulateurAvantApresState } from "./simulateurAvantApres/types";
import { watcherSaga as watchSimulateurEconomiesImpots } from "./simulateurEconomiesImpots/sagas";
import SimulateurEconomiesImpotsReducer from "./simulateurEconomiesImpots/slice";
import { SimulateurEconomiesImpotsState } from "./simulateurEconomiesImpots/types";
import { watcherSaga as watchSimulateurPlafondEconomieImpot } from "./simulateurPlafondEconomieImpot/sagas";
import SimulateurPlafondEconomieImpotReducer from "./simulateurPlafondEconomieImpot/slice";
import { SimulateurPlafondEconomieImpotState } from "./simulateurPlafondEconomieImpot/types";
import { RetirementSimulationParametersReducer } from "./simulationAssu/reducers";
import { watcherSaga as watchRetirementSimulationParameters } from "./simulationAssu/sagas";
import { RetirementSimulationParametersState } from "./simulationAssu/types";
import SupervisionReducer from "./supervision/supervisionSlice";
import { SupervisionState } from "./supervision/types";
import { watcherSaga as watchTax } from "./tax/sagas";
import TaxReducer from "./tax/taxSlice";
import { TaxState } from "./tax/types";
import TrackingSlice from "./tracking/slice";
import { TrackingState } from "./tracking/types";
import { TrackingEventReducer } from "./trackingEvent/reducer";
import { watcherSaga as watchTrackingEvent } from "./trackingEvent/sagas";
import { TrackingEventState } from "./trackingEvent/types";
import { watcherSaga as watchTunnel } from "./tunnel/sagas";
import TunnelReducer from "./tunnel/tunnelSlice";
import { TunnelState } from "./tunnel/types";
import { WorkIncomesReducer } from "./workIncomes/reducer";
import { watcherSaga as watchWorkIncomes } from "./workIncomes/sagas";
import { WorkIncomesState } from "./workIncomes/types";
import { WorkIncomesHistoryReducer } from "./workIncomesHistory/reducer";
import watchWorkIncomesHistory from "./workIncomesHistory/sagas";
import { WorkIncomesHistoryState } from "./workIncomesHistory/types";

const sagaMiddleware = createSagaMiddleware();

export interface State {
  account: AccountState;
  login: LoginState;
  signUp: SignUpState;
  tunnel: TunnelState;
  parcoursRIS: ParcoursRISState;
  family: FamilyState;
  assets: AssetState;
  loans: LoanState;
  importData: ImportState;
  privateData: PrivateDataState;
  incomes: IncomeState;
  expenses: ExpenseState;
  newProjects: NewProjectState;
  realizedDonations: RealizedDonationState;
  projects: ProjectState;
  retirementSimulationParameters: RetirementSimulationParametersState;
  tax: TaxState;
  error: ErrorState;
  workIncomes: WorkIncomesState;
  workIncomesHistory: WorkIncomesHistoryState;
  pdf: PdfState;
  parcoursRisUpload: ParcoursRisUploadState;
  parcoursRisInformationsComplementaires: ParcoursRisInformationsComplementairesState;
  parcoursRisProjection: ParcoursRisProjectionState;
  parcoursRevenusComplementaires: ParcoursRevenusComplementairesState;
  dashboard: DashboardState;
  savingPath: SavingPathState;
  assetIncomes: AssetIncomesState;
  appointment: AppointmentState;
  profile: ProfileState;
  assetPercentages: AssetPercentagesState;
  effort: EffortState;
  trackingEvent: TrackingEventState;
  features: FeaturesState;
  simulateurEconomiesImpots: SimulateurEconomiesImpotsState;
  simulateurPlafondEconomieImpot: SimulateurPlafondEconomieImpotState;
  personalInformations: PersonalInformationsState;
  promotionalOffers: PromotionalOfferState;
  simulateurAvantApres: SimulateurAvantApresState;
  supervision: SupervisionState;
  personnnalisationQuestions: PersonnalisationQuestionsState;
  personnnalisationDashboard: PersonnalisationDashboardState;
  tracking: TrackingState;
}

export const appReducer = combineReducers({
  account: AccountReducer,
  login: LoginReducer,
  signUp: SignUpReducer,
  tunnel: TunnelReducer,
  parcoursRIS: ParcoursRISReducer,
  family: FamilyReducer,
  assets: AssetsReducer,
  loans: LoansReducer,
  importData: ImportReducer,
  privateData: PrivateDataReducer,
  incomes: IncomesReducer,
  expenses: ExpensesReducer,
  newProjects: NewProjectsReducer,
  realizedDonations: RealizedDonationsReducer,
  projects: ProjectsReducer,
  retirementSimulationParameters: RetirementSimulationParametersReducer,
  workIncomes: WorkIncomesReducer,
  workIncomesHistory: WorkIncomesHistoryReducer,
  tax: TaxReducer,
  error: ErrorReducer,
  parcoursRisUpload: ParcoursRisUploadReducer,
  parcoursRisInformationsComplementaires:
    ParcoursRisInformationsComplementairesReducer,
  parcoursRisProjection: ParcoursRisProjectionReducer,
  parcoursRevenusComplementaires: ParcoursRevenusComplementairesReducer,
  pdf: PdfReducer,
  dashboard: DashboardReducer,
  savingPath: SavingPathReducer,
  assetIncomes: AssetIncomesReducer,
  appointment: AppointmentReducer,
  profile: ProfileReducer,
  assetPercentages: AssetPercentagesReducer,
  effort: EffortReducer,
  trackingEvent: TrackingEventReducer,
  features: FeaturesReducer,
  simulateurEconomiesImpots: SimulateurEconomiesImpotsReducer,
  simulateurPlafondEconomieImpot: SimulateurPlafondEconomieImpotReducer,
  personalInformations: PersonalInformationsReducer,
  promotionalOffers: PromotionalOfferReducer,
  simulateurAvantApres: SimulateurAvantApresReducer,
  supervision: SupervisionReducer,
  personnnalisationQuestions: PersonnalisationQuestionsSlice,
  personnnalisationDashboard: PersonnalisationDashboardSlice,
  tracking: TrackingSlice,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction
) => appReducer(action.type === USER_LOGOUT ? undefined : state, action);

const store: ToolkitStore<any> = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

if (window.Cypress) {
  window.store = store;
}

export function* rootSaga() {
  yield all([
    watchAccount(),
    watchLogin(),
    watchSignUp(),
    watchMembers(),
    watchAssets(),
    watchLoans(),
    watchImport(),
    watchIncomes(),
    watchExpenses(),
    watchNewProjects(),
    watchRealizedDonations(),
    watchProjects(),
    watchRetirementSimulationParameters(),
    watchTax(),
    watchWorkIncomes(),
    watchWorkIncomesHistory(),
    watchParcoursRisUpload(),
    watchParcoursRisInformationsComplementaires(),
    watchParcoursRisProjection(),
    watchPdf(),
    watchDashboard(),
    watchSavingPath(),
    watchAssetIncomes(),
    watchAppointment(),
    watchPrivateData(),
    watchTrackingEvent(),
    watchFeatures(),
    watchPersonalInformations(),
    watchErrors(),
    watchTunnel(),
    watchPromotionalOffer(),
    watchSimulateurEconomiesImpots(),
    watchSimulateurPlafondEconomieImpot(),
    watchSimulateurAvantApres(),
    watchProfile(),
    watchPersonnalisationQuestions(),
    watchPersonnalisationDashboard(),
  ]);
}

sagaMiddleware.run(rootSaga);
export { store };
