import {
   FETCH_EXPENSES,
   FETCH_SPENDING_PROFILE
} from "./types";

export const getExpensesAction = () => ({
   type: FETCH_EXPENSES
});

export const getSpendingProfileAction = () => ({
   type: FETCH_SPENDING_PROFILE
});
