import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, select, takeLeading } from "redux-saga/effects";
import { PROJECTS } from "../../website/utils/privateRoutes";
import { redirectToRelativePath } from "../../website/utils/routes/routing";
import { apiDelete, apiGet, apiPost } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { getMembersAction } from "../members/actions";
import { getNewProjects } from "../newProjects/actions";
import { getProjectsAction } from "./actions";
import {
   allProjectsDeleted,
   projectsFetched,
   retirementProjectUpdated
} from "./projectsSlice";
import {
   CREATE_RETIREMENT_PROJECT,
   DELETE_ALL_PROJECTS,
   FETCH_PROJECTS,
   Project,
   RetirementProjectValues,
   UPDATE_RETIREMENT_PROJECT_IN_STORE,
} from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_PROJECTS, safe(onDefaultError, handleGetProjects));
   yield takeLeading(CREATE_RETIREMENT_PROJECT, safe(onDefaultError, handleCreateRetirementProject));
   yield takeLeading(UPDATE_RETIREMENT_PROJECT_IN_STORE, safe(onDefaultError, handleUpdateRetirementProjectInStore));
   yield takeLeading(DELETE_ALL_PROJECTS, safe(onDefaultError, handleDeleteAllProjects));
}

function* handleGetProjects() {
   const payload: AxiosResponse<Project[]> = yield call(apiGet, `wealth/api/projects`);
   yield put(projectsFetched(payload.data));
}

function* handleCreateRetirementProject() {
   const retirementProject: RetirementProjectValues = yield select((state) => state.projects.retirementProject);
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiPost, `wealth/api/projects/retirement?persist=true`, retirementProject);
   yield put(getNewProjects());
   redirectToRelativePath(`${PROJECTS}?currentProject=${payload.data.projectId}`);
   yield put(getProjectsAction());
   yield put(getMembersAction());
}

function* handleUpdateRetirementProjectInStore(action: PayloadAction<RetirementProjectValues>) {
   yield put(retirementProjectUpdated(action.payload));
}

function* handleDeleteAllProjects() {
   yield call(apiDelete, `wealth/api/projects/`);
   yield put(allProjectsDeleted());
   yield put(getProjectsAction());
   yield put(getNewProjects());
}
