import { FC } from "react";
import { SGMediaQuery } from "sg-media-query";
import { Image } from "website/components/atoms/Image/Image";
import { Bloc5050 } from "../Bloc5050";
import { ContenusBloc5050 } from "../ContenusBloc5050";

interface ImageBloc5050Props {
  imgScr: string;
  imgScrMob: string;
  idTitre: string;
  niveauTitre?: number;
  idTexte: string;
  inverse?: boolean;
  bouton?: JSX.Element;
}

export const ImageBloc5050: FC<ImageBloc5050Props> = ({
  imgScr,
  imgScrMob,
  idTitre,
  niveauTitre = 1,
  idTexte,
  inverse = false,
  bouton,
}) => (
  <Bloc5050
    cover={
      <>
        <SGMediaQuery minwidth="md">
          <Image src={imgScr} />
        </SGMediaQuery>
        <SGMediaQuery minwidth={0} maxwidth="sm">
          <Image src={imgScrMob} />
        </SGMediaQuery>
      </>
    }
    inverse={inverse}
  >
    <ContenusBloc5050 idTitre={idTitre} niveauTitre={niveauTitre} idTexte={idTexte} bouton={bouton} />
  </Bloc5050>
);
