import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGStep, SGSteps } from "sg-steps";
import { SGTitle } from "sg-typo";
import { resetTunnelAction } from "store/tunnel/actions";
import { useScrollToTopWithCurrentStep } from "website/components/hooks/useScrollToTopWithCurrentStep";
import { SimulateurFlashPension } from "website/components/organisms/SimulateurFlash/SimulateurFlashPension/SimulateurFlashPension";
import { SimulateurFlashWorkIncomes } from "website/components/organisms/SimulateurFlash/SimulateurFlashWorkIncomes/SimulateurFlashWorkIncomes";
import { DetailTabProvider } from "website/components/providers/DetailTabProvider";
import { State } from "../../store/store";
import { SimulateurFlashFamilySituation } from "../components/organisms/SimulateurFlash/SimulateurFlashFamilySituation/SimulateurFlashFamilySituation";
import { SimulateurFlash } from "../components/templates/SimulateurFlash/SimulateurFlash";

export enum FLASH_STEPS {
  SITUATION,
  REVENUS,
  PENSION,
}

export const SimulateurFlashPage: FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const currentStep = useSelector<State, number>(
    (state) => state.tunnel.currentStep
  );
  const stepComponentsAssu = [
    SimulateurFlashFamilySituation,
    SimulateurFlashWorkIncomes,
    SimulateurFlashPension,
  ];
  const [reset, setReset] = useState(false);

  const categories = [
    intl.formatMessage({ id: "tunnel.step.etape2" }),
    intl.formatMessage({ id: "tunnel.step.etape3" }),
    intl.formatMessage({ id: "tunnel.step.pension" }),
  ];

  useEffect(() => {
    dispatch(resetTunnelAction());
    // On n'affiche pas le composant trop tôt, pour éviter un tracking d'ancien parcours
    setReset(true);
  }, []);

  useScrollToTopWithCurrentStep(currentStep);

  const getStatus = (index: number): string => {
    const indexStep: number = currentStep;
    if (indexStep === index) {
      return "process";
    }
    if (indexStep < index) {
      return "wait";
    }

    return "finish";
  };

  return reset ? (
    <SGLayout tagName="div">
      <SGContent disableautomargin>
        <SGSpace direction="vertical" size="md">
          <SGTitle key="titre" textalign="center" level={1}>
            {intl.formatMessage({ id: "tunnel.edit.title" })}
          </SGTitle>
          <SGBox
            key="box"
            padding={isPhone ? { bottom: "md" } : { bottom: "xl", top: "sm" }}
          >
            <SGSteps current={currentStep}>
              {categories.map((categorie, index) => (
                <SGStep
                  title={categorie}
                  status={getStatus(index)}
                  key={categorie}
                  progress={currentStep === index ? 50 : 100}
                />
              ))}
            </SGSteps>
          </SGBox>
        </SGSpace>
      </SGContent>
      <SGLayout background="light" tagName="div">
        <SGHeader disableautomargin />
        <SGContent disableautomargin>
          <SGBox span={isPhone ? 12 : 8}>
            <DetailTabProvider>
              <SimulateurFlash steps={stepComponentsAssu} />
            </DetailTabProvider>
          </SGBox>
        </SGContent>
      </SGLayout>
    </SGLayout>
  ) : null;
};
