import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Expense, ExpenseState, SpendingProfile} from "./types";
import {storeDeepEquals} from "../utils";

const initialState: ExpenseState = {
   expenses: new Array<Expense>(),
   hasFetchedExpenses: false,
   spendingProfile: SpendingProfile.MEDIUM_SAVING,
   hasFetchedSpendingProfile: false
};

export const expensesSlice = createSlice({
   name: "expenses",
   initialState,
   reducers: {
      setFetchedExpensesToFalse: (currentState) => ({
         ...currentState,
         hasFetchedExpenses: false
      }),
      expensesFetched: (currentState, action: PayloadAction<Expense[]>) => ({
         ...currentState,
         expenses: storeDeepEquals(currentState.expenses, action.payload) ? currentState.expenses : action.payload,
         hasFetchedExpenses: true
      }),
      spendingProfileFetched: (currentState, action: PayloadAction<SpendingProfile>) => ({
         ...currentState,
         spendingProfile: action.payload,
         hasFetchedSpendingProfile: true
      }),
   }
});

export const {
   expensesFetched,
   setFetchedExpensesToFalse,
   spendingProfileFetched,
} = expensesSlice.actions;

export default expensesSlice.reducer;
