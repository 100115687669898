import { SyntheticEvent } from "react";
import { SGLink } from "sg-link";
import { externalURL } from "website/utils/externalUrl";
import { AllerPlusLoinTuile } from "./SectionAllerPlusLoin";

export enum NomPageEnum {
  PER = "per",
  ASV = "asv",
  MON_OBJECTIF = "monObjectif",
  SYNTHESE_RETRAITE = "syntheseRetraite",
  MON_PLAN = "monPlan",
}

export enum TabNosSolutionsEnum {
  SOUSCRIPTION = "souscription",
  ALIMENTATION = "alimentation",
  FISCALITE = "fiscalite",
  MODE_DE_GESTION = "modeDeGestion",
  RECUPERATION = "recuperation",
}

export const ordreTuilesPage = {
  [NomPageEnum.PER]: [
    AllerPlusLoinTuile.agenda,
    AllerPlusLoinTuile.diagEpargne,
    AllerPlusLoinTuile.sei,
  ],
  [NomPageEnum.ASV]: [
    AllerPlusLoinTuile.agenda,
    AllerPlusLoinTuile.diagEpargne,
  ],
  [NomPageEnum.MON_OBJECTIF]: [
    AllerPlusLoinTuile.solutions,
    AllerPlusLoinTuile.diagEpargne,
    AllerPlusLoinTuile.agenda,
  ],
  [NomPageEnum.SYNTHESE_RETRAITE]: [
    AllerPlusLoinTuile.asv,
    AllerPlusLoinTuile.per,
    AllerPlusLoinTuile.agenda,
  ],
  [NomPageEnum.MON_PLAN]: [
    AllerPlusLoinTuile.infoRetraite,
    AllerPlusLoinTuile.sapiendo,
  ],
};

export function parseLink(
  word: string,
  openLink: (href: string, event?: SyntheticEvent) => boolean
): JSX.Element {
  const split = word.split("#;#");
  const label = split[1];
  const link = externalURL[split[0]];

  return (
    <SGLink
      href={link}
      onClick={(event: SyntheticEvent) => openLink(link, event)}
    >
      {label}
    </SGLink>
  );
}
