import {Asset} from "../assets/types";
import {CHESTNUT, ElxColor, FOREST_GREEN, SG_BLACK, SG_ORANGE, SG_PINK, TEA_GREEN} from "../../website/utils/graphs/colors";

export interface IncomeState {
   incomes: Income[];
   hasFetched: boolean;
}

export interface Income {
   id: number;
   name: string;
   monthlyAmount: number;
   memberId: number | undefined;
   type: IncomeType;
   assetId: number;
}

export interface PlannedSavingsBuybackIncome extends Income {
   savingsAsset: Asset;
}

export type IncomeCategoryKey =
   | "WORK_INCOME"
   | "CHILD_SUPPORT_INCOME"
   | "REAL_ESTATE_INCOME"
   | "RETIREMENT_INCOME"
   | "PLANNED_SAVINGS_BUYBACK"
   | "OTHER_INCOME"
   | "REAL_COSTS_REAL_ESTATE_INCOME";

export interface IncomeGraphCategory {
   intlKey: IncomeCategoryKey;
   color: ElxColor;
}

export const WORK_INCOME_GRAPH_CATEGORY: IncomeGraphCategory = {
   intlKey: "WORK_INCOME",
   color: FOREST_GREEN
};
export const REAL_ESTATE_INCOME_GRAPH_CATEGORY: IncomeGraphCategory = {
   intlKey: "REAL_ESTATE_INCOME",
   color: SG_PINK
};
export const CHILD_SUPPORT_INCOME_GRAPH_CATEGORY: IncomeGraphCategory = {
   intlKey: "CHILD_SUPPORT_INCOME",
   color: CHESTNUT
};
export const RETIREMENT_INCOME_GRAPH_CATEGORY: IncomeGraphCategory = {
   intlKey: "RETIREMENT_INCOME",
   color: TEA_GREEN
};
export const PLANNED_SAVINGS_BUYBACK_GRAPH_CATEGORY: IncomeGraphCategory = {
   intlKey: "PLANNED_SAVINGS_BUYBACK",
   color: SG_ORANGE
};
export const OTHER_INCOME_GRAPH_CATEGORY: IncomeGraphCategory = {
   intlKey: "OTHER_INCOME",
   color: SG_BLACK
};

export const IncomeType = {
   WORK_INCOME: "WORK_INCOME",
   REAL_ESTATE_INCOME: "REAL_ESTATE_INCOME",
   REAL_COSTS_REAL_ESTATE_INCOME: "REAL_COSTS_REAL_ESTATE_INCOME",
   MICRO_FONCIER_REAL_ESTATE_INCOME: "MICRO_FONCIER_REAL_ESTATE_INCOME",
   MICRO_BIC_REAL_ESTATE_INCOME: "MICRO_BIC_REAL_ESTATE_INCOME",
   DEFISCALISATION_REAL_ESTATE_INCOME: "DEFISCALISATION_REAL_ESTATE_INCOME",
   SCELLIER_REAL_ESTATE_INCOME: "SCELLIER_REAL_ESTATE_INCOME",
   DUFLOT_9_ANS_REAL_ESTATE_INCOME: "DUFLOT_9_ANS_REAL_ESTATE_INCOME",
   PINEL_6_ANS_REAL_ESTATE_INCOME: "PINEL_6_ANS_REAL_ESTATE_INCOME",
   PINEL_9_ANS_REAL_ESTATE_INCOME: "PINEL_9_ANS_REAL_ESTATE_INCOME",
   PINEL_12_ANS_REAL_ESTATE_INCOME: "PINEL_12_ANS_REAL_ESTATE_INCOME",
   CENSI_BOUVARD_REAL_ESTATE_INCOME: "CENSI_BOUVARD_REAL_ESTATE_INCOME",
   CHILD_SUPPORT_INCOME: "CHILD_SUPPORT_INCOME", // Pension alimentaire
   RETIREMENT_INCOME: "RETIREMENT_INCOME",
   PLANNED_SAVINGS_BUYBACK: "PLANNED_SAVINGS_BUYBACK",
   OTHER_INCOME: "OTHER_INCOME"
};

export type IncomeType = typeof IncomeType[keyof typeof IncomeType];

// actions
export const FETCH_INCOMES = "FETCH_INCOMES";
export const DELETE_INCOME = "DELETE_INCOME";
