import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Loan, LoanState} from "./types";
import {insertElement, removeElement, updateElement} from "../utils";

const initialState: LoanState = {loans: new Array<Loan>(), loansImported: new Array<Loan>(), hasFetched: false};

export const loansSlice = createSlice({
   name: "loans",
   initialState,
   reducers: {
      loansFetched: (currentState, action: PayloadAction<Loan[]>) => ({...currentState, loans: action.payload, hasFetched: true}),
      loansImportedFetched: (currentState, action: PayloadAction<Loan[]>) => ({
         ...currentState,
         loansImported: action.payload
      })
   }
});

export const { loansFetched, loansImportedFetched} = loansSlice.actions;

export default loansSlice.reducer;
