import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateAccountAction } from "store/account/actions";
import { setIsOnboarded, updatePrefs } from "store/dashboard/actions";
import { DashboardAgeType, User } from "store/dashboard/types";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import {
  PAGE_CATEGORY_3_ONBOARDING,
  PAGE_NAME_ESTIMATION_PENSION,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAccount } from "website/components/hooks/useAccount";
import { LegalNoticesModal } from "website/components/molecules/LegalNoticesModal/LegalNoticesModal";
import { PensionSituation } from "website/components/organisms/PensionSituation/PensionSituation";
import { DASHBOARD } from "website/utils/privateRoutes";

export const TunnelPension: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { trackPage } = useTracking();
  const history = useHistory();
  const account = useAccount();
  const dashboardState = useDashboardState();

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const possedeDroitsAction = possedeDroitsActionUtilisateur(supervisionState);

  const [showLegalNotices, setShowLegalNotices] = useState<boolean>(false);

  const changeSelectedAgeRetirement = (type: DashboardAgeType) => {
    const req: User = {
      choosenAge: type,
    };
    dispatch(updatePrefs(req));
    dispatch(updateAccountAction({ ...account, tunnelCompleted: true }));
  };

  useEffect(() => {
    trackPage(
      PAGE_CATEGORY_3_ONBOARDING,
      PAGE_NAME_ESTIMATION_PENSION,
      PAGE_TYPE_FORMULAIRE,
      "inscription",
      "5"
    );
  }, []);

  useEffect(() => {
    if (
      dashboardState.dashboard.choosenAge !== undefined &&
      account.tunnelCompleted
    ) {
      dispatch(setIsOnboarded());
      history.push(DASHBOARD);
    }
  }, [dashboardState, account]);

  useEffect(() => {
    setShowLegalNotices(
      dashboardState.dashboard.legalNoticesAcceptanceDate === undefined
    );
  }, [dashboardState]);

  return (
    <>
      <PensionSituation
        changeSelectedAgeRetirement={changeSelectedAgeRetirement}
        accessible={possedeDroitsAction}
      />
      {showLegalNotices && (
        <LegalNoticesModal
          visible={showLegalNotices}
          setVisible={setShowLegalNotices}
          formStep="4"
        />
      )}
    </>
  );
};
