import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SGAvenirStyledEpargne } from "sg-icon-pack-base";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { NOS_SOLUTIONS_PER } from "website/utils/privateRoutes";
import { TuileCliquable } from "../TuileCliquable/TuileCliquable";

export const TuilePER: FC = () => {
  const { trackClick } = useTracking();

  return (
    <TuileCliquable
      icone={<SGAvenirStyledEpargne />}
      iconeTaille="s"
      titre={<FormattedMessage id="sectionAllerPlusLoin.tuile.per.titre" />}
      texte={<FormattedMessage id="sectionAllerPlusLoin.tuile.per.texte" />}
      onClick={() => {
        trackClick("clic-sur-card-plan-epargne-retraite");
      }}
      lien={`#${NOS_SOLUTIONS_PER}`}
    />
  );
};
