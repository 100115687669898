import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { loansFetched } from "./loansSlice";
import { FETCH_LOANS, Loan } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_LOANS, safe(onDefaultError, handleGetLoans));
}

function* handleGetLoans() {
   const payload: AxiosResponse<Loan[]> = yield call(apiGet, `wealth/api/loans`);
   yield put(loansFetched(payload.data));
}
