import { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent } from "sg-layout";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SectionAllerPlusLoin } from "website/components/molecules/NosSolutions/SectionAllerPlusLoin";
import { SectionTabsNosSolutions } from "website/components/molecules/NosSolutions/SectionTabsNosSolutions";
import {
  NomPageEnum,
  TabNosSolutionsEnum,
  ordreTuilesPage,
} from "website/components/molecules/NosSolutions/utils";
import { StickySGAgenda } from "website/components/molecules/StickySGAgenda/StickySGAgenda";
import {
  BlocPicto,
  IconeBlocPicto,
} from "website/components/molecules/Templates/BlocPicto";
import { DASHBOARD } from "website/utils/privateRoutes";
import { MentionsSolutions } from "./MentionsSolutions";

interface NosSolutionsTemplateProps {
  nom: NomPageEnum;
  icones: IconeBlocPicto[];
  tabs: TabNosSolutionsEnum[];
  spanSectionPointsCles: number;
  trackingName: string;
}

const NosSolutionsTemplate: FunctionComponent<NosSolutionsTemplateProps> = (
  props: NosSolutionsTemplateProps
) => {
  const { nom, icones, tabs, spanSectionPointsCles, trackingName } = props;
  const intl = useIntl();
  const { trackPage, trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  useEffect(() => {
    trackPage("nos-solutions", trackingName, "gestion-ecran");
  }, []);

  return (
    <SGContent>
      <SGBox span={isPhone ? 12 : 10} margin={{ bottom: "md" }}>
        <SGSpace
          size="sm"
          textalign={isPhone ? "left" : "center"}
          direction="vertical"
        >
          <SGTitle key="titre" level={1}>
            {intl.formatMessage({
              id: `${nom}.titre`,
            })}
          </SGTitle>
          {nom === NomPageEnum.ASV && (
            <SGText key="text" size="l">
              {intl.formatMessage({
                id: `${nom}.description`,
              })}
            </SGText>
          )}
        </SGSpace>
      </SGBox>
      <SGBox
        padding={{ bottom: isPhone ? "xxl" : "lg" }}
        span={spanSectionPointsCles === 6 ? 8 : 12}
      >
        <BlocPicto
          icones={icones}
          nom={nom}
          spanBreakpoint={{ sm: spanSectionPointsCles }}
        />
      </SGBox>
      <SGBox span={isPhone ? 12 : 8} padding={{ top: isPhone ? "xxl" : "lg" }}>
        <SectionTabsNosSolutions tabs={tabs} nom={nom} />
      </SGBox>
      <SGBox margin={{ top: "md", bottom: "xl" }}>
        <SGButtonGroup disableautomargin align="center">
          <SGLink
            href={`#${DASHBOARD}`}
            type="primary"
            onClick={() =>
              trackClick("clic-sur-retourner-a-mon-espace-retraite")
            }
          >
            {intl.formatMessage({
              id: `${nom}.bouton.retour`,
            })}
          </SGLink>
        </SGButtonGroup>
      </SGBox>
      <SGBox margin={{ bottom: isPhone ? "lg" : "xxl" }}>
        <SGGridRow gutter={[0, isPhone ? 16 : 32]} justify="center">
          <SGGridCol span={12}>
            <SectionAllerPlusLoin
              nom={nom}
              ordreTuilesPage={ordreTuilesPage[nom]}
            />
          </SGGridCol>
          <SGGridCol span={12} sm={8}>
            <MentionsSolutions nom={nom} />
          </SGGridCol>
        </SGGridRow>
      </SGBox>
      <StickySGAgenda />
    </SGContent>
  );
};

export { NosSolutionsTemplate };
