import { FC } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useDroitsSegment } from "website/components/hooks/useDroitsSegment";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { externalURL } from "website/utils/externalUrl";
import { BESOIN_EPARGNE } from "website/utils/privateRoutes";
import { TuileCliquable } from "../TuileCliquable/TuileCliquable";

interface TuileDiagnosticEpargneProps {
  icone: JSX.Element;
  alignchildren?: "center";
  iconeTaille?: "s";
  idTracking?: string;
}

export const TuileDiagnosticEpargne: FC<TuileDiagnosticEpargneProps> = ({
  icone,
  iconeTaille = "m",
  alignchildren,
  idTracking = "clic-sur-diagnostic-epargne",
}) => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const { trackClick } = useTracking();
  const openLink = useOpenLink();

  const { isDiagnosticEpargneAvailable } = useDroitsSegment();

  const lien =
    pathname === BESOIN_EPARGNE
      ? externalURL.EXTERNAL_URL_EPARGNER_PLACER
      : externalURL.EXTERNAL_URL_DIAGNOSTIC_EPARGNE;

  return isDiagnosticEpargneAvailable ? (
    <TuileCliquable
      icone={icone}
      iconeTaille={iconeTaille}
      titre={intl.formatMessage({
        id: "sectionAllerPlusLoin.tuile.diagEpargne.titre",
      })}
      texte={intl.formatMessage({
        id: "sectionAllerPlusLoin.tuile.diagEpargne.texte",
      })}
      onClick={() => {
        trackClick(idTracking);
        openLink(lien);
      }}
      alignchildren={alignchildren}
    />
  ) : null;
};
