import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGCard } from "sg-card";
import { SGTabPane, SGTabs } from "sg-tabs";
import { computeAdditionalIncomes } from "store/assets/utils";
import { updatePrefs } from "store/dashboard/actions";
import { DashboardAge, User, hasFetchedDashboard } from "store/dashboard/types";
import { getMonthlyIncome, getSavingEffort } from "store/savingPath/actions";
import {
    MonthlyIncomeRequest,
    SavingEffortRequest,
    SavingPathState,
    convertAgeTypeToWealthProfile,
    hasFetchedSavingPathMonthlyEffort,
    hasFetchedSavingPathObjective,
} from "store/savingPath/types";
import { State } from "store/store";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useMontantsMax } from "website/components/hooks/dashboard/useMontantsMax";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAssets } from "website/components/hooks/useAssets";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { EffortEpargneTab } from "./EffortEpargneTab/EffortEpargneTab";

export enum OngletEffortEpargne {
    objectif = "objectif",
    epargne = "epargne",
}

interface EffortEpargneProps {
    selectedRetirementAge: DashboardAge;
    ongletDefaut: OngletEffortEpargne
    onTabChange: (onglet: OngletEffortEpargne) => void;
}

/**
 * Composant chapeau de l'objectif/épargne du Dashboard.
 * Permet de gérer le fonctionnel et la gestion des deux onglets.
 */
const EffortEpargne: FunctionComponent<EffortEpargneProps> = (props: EffortEpargneProps) => {
    const { selectedRetirementAge, ongletDefaut, onTabChange } = props;
    const savingPathState = useSelector<State, SavingPathState>((state) => state.savingPath);
    const dashboardState = useDashboardState();
    const { maxObjectifInput, maxEpargneInput } = useMontantsMax();

    const { assets, hasFetchedAssets, assetIncomes, hasFetchedIncomes } = useAssets();

    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState<OngletEffortEpargne>(ongletDefaut);
    const fromObjectif = currentTab === OngletEffortEpargne.objectif;
    const wealthProfile = useMemo(() => convertAgeTypeToWealthProfile(selectedRetirementAge.type), [selectedRetirementAge.type]);

    const [objectifRevenu, setObjectifRevenu] = useState<number>();
    const [savedObjectifRevenu, setSavedObjectifRevenu] = useState<number>();

    const [montantEpargne, setMontantEpargne] = useState<number>();
    const [savedMontantEpargne, setSavedMontantEpargne] = useState<number>();

    /**
     * Calcul du revenu à financer en fonction de l'objectif, du patrimoine et de la pension de retraite.
     */
    const revenuAFinancer = useMemo(() => {
        let total = objectifRevenu ?? 0;
        if (!hasFetchedAssets || !hasFetchedIncomes || total === 0) {
            return 0;
        }

        if (selectedRetirementAge.retirementMonthlyIncome >= 0) {
            total -= roundDown10(selectedRetirementAge.retirementMonthlyIncome);
        }
        const additionalIncome = roundDown10(computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge));

        return total - additionalIncome;
    }, [hasFetchedAssets, assets, hasFetchedIncomes, assetIncomes, objectifRevenu, selectedRetirementAge.retirementMonthlyIncome]);

    const [hasChangedValueObjectifRevenu, setHasChangedValueObjectifRevenu] = useState<boolean>(false);
    const [hasChangedValueMontantEpargne, setHasChangedValueMontantEpargne] = useState<boolean>(false);

    const intl = useIntl();
    const { trackTab, trackDisclaimer } = useTracking();

    /**
     * Init avec infos du dashboard => Mise à jour de l'objectif et récupération de l'effort d'épargne (ou l'inverse)
     */
    useEffect(() => {
        if (hasFetchedDashboard(dashboardState) && savedObjectifRevenu === undefined) {
            if (dashboardState.dashboard.monthlyIncomeGoal !== undefined) {
                setObjectifRevenu(dashboardState.dashboard.monthlyIncomeGoal);
                setSavedObjectifRevenu(dashboardState.dashboard.monthlyIncomeGoal);
            }

            // On initialise l'effort d'épargne si l'objectif de revenu est défini
            // sinon on initialise les revenus de retraite sans effort d'épargne
            // Gère aussi l'arrivée sur l'onglet épargne
            const firstObjective = dashboardState.dashboard.monthlyIncomeGoal ?? 0;
            if (firstObjective > 0) {
                // On passe le même objectif pour éviter la persistance inutile
                callSavingPathMotor(firstObjective, firstObjective, false, wealthProfile);
            }
        }
    }, [dashboardState]);

    // Si on édite l'objectif, on calcule le montant d'épargne
    useEffect(() => {
        if (fromObjectif && savedObjectifRevenu !== objectifRevenu) {
            debouncedCallEffortEpargne(objectifRevenu ?? 0, savedObjectifRevenu ?? 0, false, wealthProfile);
        }
    }, [fromObjectif, objectifRevenu, savedObjectifRevenu]);

    // Si on édite le montant d'épargne, on calcule l'objectif
    useEffect(() => {
        if (!fromObjectif && savedMontantEpargne !== montantEpargne) {
            // Si on édite le montant d'épargne, on calcule l'objectif
            debouncedCallEffortEpargne(montantEpargne ?? 0, savedMontantEpargne ?? 0, true, wealthProfile);
        }
    }, [fromObjectif, objectifRevenu, montantEpargne]);

    // Déclencher uniquement la première fois sur la page
    useEffect(() => {
        if (hasChangedValueObjectifRevenu) {
            trackDisclaimer("disclaimer-resultats-saisie-objectif-revenus");
        }
    }, [hasChangedValueObjectifRevenu]);

    // Déclencher uniquement la première fois sur la page
    useEffect(() => {
        if (hasChangedValueMontantEpargne) {
            trackDisclaimer("disclaimer-resultats-saisie-objectif-epargne");
        }
    }, [hasChangedValueMontantEpargne]);

    // Récupération de l'objectif ou du revenu via le moteur
    useEffect(() => {
        // On ne met à jour que la valeur du bas
        // Si on a changé d'onglet, on ne met pas à jour les données ici
        if (objectifRevenu !== undefined && hasFetchedSavingPathMonthlyEffort(savingPathState)
                && savingPathState.monthlyEffort !== savedMontantEpargne) {
            const newEffort = roundDown10(savingPathState.monthlyEffort);
            setMontantEpargne(newEffort);
            setSavedMontantEpargne(newEffort);
            // Appel OK, on sauvegarde l'objectif saisi
            setSavedObjectifRevenu(objectifRevenu);
        } else if (montantEpargne !== undefined && hasFetchedSavingPathObjective(savingPathState)
                && savingPathState.objective !== savedObjectifRevenu) {
            const newObjective = roundDown10(savingPathState.objective);
            setObjectifRevenu(newObjective);
            setSavedObjectifRevenu(newObjective);
            // Appel OK, on sauvegarde le montant saisi
            setSavedMontantEpargne(montantEpargne);

            saveUserRetirementPathObjective(newObjective);
        }
    }, [savingPathState]);

    const changeTab = (target: OngletEffortEpargne) => {
        if (target !== currentTab) {
            setCurrentTab(target);
            onTabChange(target);
            // Tracking [Onglet] Changement d'onglet "revenus" / "épargne"
            trackTab(`clic-sur-onglet-${target === OngletEffortEpargne.objectif ? "mon-besoin-de-revenus" : "mon-epargne"}`);

            // On arrondit l'autre valeur au changement d'onglet, pour ne pas avoir d'unité
            // Seulement si elle est définie
            if (fromObjectif && montantEpargne) {
                roundMontantEpargne();
            } else if (!fromObjectif && objectifRevenu) {
                roundObjectifRevenu();
            }

            // Si changement d'onglet pendant un recalcul, on remet l'ancienne valeur
            if (fromObjectif && objectifRevenu !== savedObjectifRevenu) {
                setObjectifRevenu(savedObjectifRevenu);
            } else if (!fromObjectif && montantEpargne !== savedMontantEpargne) {
                setMontantEpargne(savedMontantEpargne);
            }
        }
    };

    const roundObjectifRevenu = () => {
        setObjectifRevenu((value) => roundDown10(value ?? 0));
    };

    const roundMontantEpargne = () => {
        setMontantEpargne((value) => roundDown10(value ?? 0));
    };

    const saveUserRetirementPathObjective = (newObjective: number) => {
        const user: User = {
            monthlyIncomeGoal: newObjective,
        };
        dispatch(updatePrefs(user));
    };

    /**
     * Décale l'appel au moteur et annule les appels successifs trop courts
     */
    const debouncedCallEffortEpargne = useCallback(
        debounce((val: number, oldVal: number, fromMonthlyEffort: boolean, wp: string) => callSavingPathMotor(val, oldVal, fromMonthlyEffort, wp), 400),
        [] // will be created only once initially
    );

    /**
     * Appelle le moteur dans un sens ou dans l'autre
     *
     * @param val Valeur à envoyer
     * @param fromMonthlyEffort Si oui, on envoie l'effort d'épargne
     * @param wp Profil Wealth
     */
    const callSavingPathMotor = (val: number, oldVal: number, fromMonthlyEffort: boolean, wp: string) => {
        if (fromMonthlyEffort) {
            const req: MonthlyIncomeRequest = {
                profile: wp,
                monthlySavingEffort: val,
            };
            dispatch(getMonthlyIncome(req));
        } else {
            // On ne sauvegarde que si l'objectif a changé
            if (val !== oldVal) {
                saveUserRetirementPathObjective(val);
            }
            const req: SavingEffortRequest = {
                profile: wp,
                monthlyIncomeObjective: val,
            };
            dispatch(getSavingEffort(req));
        }
    };

    return (
        <SGTabs disableautomargin activeKey={currentTab} onChange={(onglet: OngletEffortEpargne) => changeTab(onglet)}>
            <SGTabPane
                ariaId={OngletEffortEpargne.objectif}
                tab={intl.formatMessage({ id: `monObjectif.effortEpargne.objectif.tab` })}
                key={OngletEffortEpargne.objectif}
            >
                {currentTab === OngletEffortEpargne.objectif && (
                    <SGCard>
                        <EffortEpargneTab
                            currentTab={currentTab}
                            editableValue={objectifRevenu}
                            setEditableValue={setObjectifRevenu}
                            maxValueInput={maxObjectifInput}
                            otherValue={montantEpargne}
                            revenuAFinancer={revenuAFinancer}
                            setHasChangedValue={setHasChangedValueObjectifRevenu}
                            onResetInput={() => {
                                setSavedObjectifRevenu(undefined);
                                setSavedMontantEpargne(undefined);
                                setMontantEpargne(undefined);
                            }}
                        />
                    </SGCard>
                )}
            </SGTabPane>
            <SGTabPane
                ariaId={OngletEffortEpargne.epargne}
                tab={intl.formatMessage({ id: `monObjectif.effortEpargne.epargne.tab` })}
                key={OngletEffortEpargne.epargne}
            >
                {currentTab === OngletEffortEpargne.epargne && (
                    <SGCard>
                        <EffortEpargneTab
                            currentTab={currentTab}
                            editableValue={montantEpargne}
                            setEditableValue={setMontantEpargne}
                            maxValueInput={maxEpargneInput}
                            otherValue={objectifRevenu}
                            revenuAFinancer={revenuAFinancer}
                            setHasChangedValue={setHasChangedValueMontantEpargne}
                            onResetInput={() => {
                                setSavedMontantEpargne(undefined);
                                setSavedObjectifRevenu(undefined);
                                setObjectifRevenu(undefined);
                            }}
                        />
                    </SGCard>
                )}
            </SGTabPane>
        </SGTabs>
    );
};

export { EffortEpargne };
