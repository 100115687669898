import { FC } from "react";
import { useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGText, SGTitle } from "sg-typo";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import {
  NomPageEnum,
  parseLink,
} from "website/components/molecules/NosSolutions/utils";
import {
  getBoxMargin,
  getLi,
  getTextStrong,
  getTextSup,
  getUl,
} from "website/utils/formatting/ValuesFormattedMessage";

interface MentionsSolutionsProps {
  nom: NomPageEnum;
}

export const MentionsSolutions: FC<MentionsSolutionsProps> = (props) => {
  const { nom } = props;
  const intl = useIntl();
  const openLink = useOpenLink();

  return (
    <>
      <SGText size="xs" whitespace="pre-line">
        {intl.formatMessage(
          {
            id: `${nom}.section.allerPlusLoin.indices`,
          },
          {
            b: getTextStrong,
            sup: getTextSup,
            ul: getUl,
            li: getLi,
            box: (word: string) => getBoxMargin(word, { bottom: "sm" }),
            link: (word: string) => parseLink(word[0], openLink),
          }
        )}
      </SGText>
      <SGAccordion expandIconPosition="right" divider>
        <SGAccordionPanel
          header={
            <SGTitle level={4}>
              {intl.formatMessage({
                id: `${nom}.section.allerPlusLoin.mention.titre`,
              })}
            </SGTitle>
          }
        >
          <SGText>
            {intl.formatMessage(
              {
                id: `${nom}.section.allerPlusLoin.mention.texte`,
              },
              {
                box: (word: string) => getBoxMargin(word, { bottom: "md" }),
              }
            )}
          </SGText>
        </SGAccordionPanel>
      </SGAccordion>
    </>
  );
};
