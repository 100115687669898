import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RealizedDonation, RealizedDonationState} from "./types";
import {insertElement, removeElement, updateElement} from "../utils";

const initialState: RealizedDonationState = {realizedDonations: new Array<RealizedDonation>(), hasFetched: false};

export const realizedDonationsSlice = createSlice({
   name: "realizedDonations",
   initialState,
   reducers: {
      realizedDonationsFetched: (currentState, action: PayloadAction<RealizedDonation[]>) => ({
         ...currentState,
         realizedDonations: action.payload,
         hasFetched: true
      })
   }
});

export const {realizedDonationsFetched} = realizedDonationsSlice.actions;

export default realizedDonationsSlice.reducer;
