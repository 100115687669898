import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGMarketingCard } from "sg-card";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTag } from "sg-tag";
import { SGTitle } from "sg-typo";
import { Image } from "website/components/atoms/Image/Image";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { ARTICLES } from "website/utils/privateRoutes";
import { OffreType } from "./PromotionalBanner";

interface PromotionalBannerPersoProps {
  offreType: OffreType;
}

const PromotionalBannerPerso: FunctionComponent<PromotionalBannerPersoProps> = (
  props: PromotionalBannerPersoProps
) => {
  const { offreType } = props;
  const { trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const intl = useIntl();
  const alt = intl.formatMessage({ id: `dashboard.banner.title.${offreType}` });

  const getLinkHref = () => {
    if (offreType === OffreType.FEMME) {
      return `#${ARTICLES}/impact-maternite-retraite`;
    }

    return "";
  };

  const onClickLink = () => {
    if (offreType === OffreType.FEMME) {
      trackClick(
        "banniere::clic-sur-lire-l-article-quel-est-l-impact-du-conge-maternite-sur-ma-retraite"
      );
    }
  };

  return (
    <SGMarketingCard
      clickable
      cover={
        <Image
          src={`images/dashboard/offre-${offreType}-banner${
            isPhone ? "" : "-desktop"
          }.jpg`}
          alt={alt}
        />
      }
      actions={
        <SGLink
          href={getLinkHref()}
          onClick={onClickLink}
          disableautomargin
          type="secondary"
          size="sm"
        >
          <FormattedMessage id={`dashboard.banner.link.${offreType}`} />
        </SGLink>
      }
    >
      <SGTag tagcolor="1">
        <FormattedMessage id={`dashboard.banner.tag.${offreType}`} />
      </SGTag>
      <SGBox margin={{ top: "sm" }}>
        <SGTitle level={2} visuallevel={3}>
          <FormattedMessage id={`dashboard.banner.title.${offreType}`} />
        </SGTitle>
      </SGBox>
    </SGMarketingCard>
  );
};

export { PromotionalBannerPerso };
